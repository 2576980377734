import { MainLayout } from 'layouts';
import { HomePage, QuestionDisablePage, InternalAccountPage } from 'views';

const routes = [
  {
    path: '/',
    component: MainLayout,
    exact: true,
    routes: [
      {
        path: '/',
        component: HomePage,
        name: 'home',
        exact: true,
      }
    ],
  },
  {
    path: '/questionDisable',
    component: MainLayout,
    exact: true,
    routes: [
      {
        path: '/questionDisable',
        component: QuestionDisablePage,
        name: 'QuestionDisablePage',
        exact: true,
      }
    ],
  },
  {
    path: '/authorityManagement',
    component: MainLayout,
    routes: [
      {
        path: '/authorityManagement/internalAccount',
        component: InternalAccountPage,
        name: 'InternalAccountPage',
        exact: true,
      }
    ],
  },
];
export default routes;
