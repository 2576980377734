import React, { useState, useEffect, useMemo } from 'react';
import { Input, Select, Table, Pagination, fetchDataMachine, FETCH_DATA_EVENT, setSelectOptions, sliceTableData, Modal, Box, PickListDropdown, openNotificationWithIcon, CloseableTag } from '@oneclass/ui-components';
import { Button, Checkbox } from '@oneclass/onedesign';
import { PlusOutline, EditSolid, SyncAltSolid } from '@onedesign/icon';
import { getSpecificationList, getSpecificationImportedList, postSpecificationImport, getSpecificationRelated, putSpecificationEdit, putSpecificationRefresh } from 'api/specification';
import { useMachine } from '@xstate/react';
import { useBoolean, useSelections } from 'ahooks';
import { StyledHeader, StyledFilterBar } from 'styles/index';
import { StyledHomePage, StyledFlexBox, StyledSelectBtn } from './HomePage.style';
import { Tooltip } from 'antd';

const intersection = (target, compare) => {
  const arrSet = new Set(compare);
  return arrSet.has(target);

};

const intersectionArray = (target, compare) => {
  const arrSet = new Set(compare);
  let isFilter = false;
  target.forEach((item) => {
    if (arrSet.has(item)) {
      isFilter = true;
      return;
    }
  });
  return isFilter;
};

const search = (target, compare) => compare.indexOf(target) !== -1;

const { Header, Body, Row, Item } = Table;
export const HomePage = ({ className }) => {
  const [year, setYear] = useState('109');
  const [editParams, setEditParams] = useState({
    uid: null,
    year: null
  });
  const [editUseType, setEditUseType] = useState('');
  const [importType, setImportType] = useState('General');
  const [onlyShowNotImported, setOnlyShowNotImported] = useState(false);
  const [onlyShowChecked, { toggle: setOnlyShowCheckedToggle, setFalse: setOnlyShowCheckedFalse }] = useBoolean(false);
  const [selectParams, setSelectParams] = useState({
    education: [],
    books: [],
    version: [],
    subject: [],
    usetype: [],
    name: '',
  });
  const [importedYear, setImportedYear] = useState('109');
  const [importedFilterParams, setImportedFilterParams] = useState({
    education: [],
    books: [],
    version: [],
    subject: [],
    usetype: [],
    name: '',
  });
  const [pageState, setPageState] = useState({
    currentPage: 1,
    limit: 10,
  });
  const [importModalVisible, { toggle: setImportModalToggle, setFalse: setImportModalFalse }] = useBoolean(false);
  const [importSecModalVisible, { toggle: setImportSecModalToggle, setFalse: setImportSecModalFalse }] = useBoolean(false);
  const [editModalVisible, { toggle: setEditModalToggle, setFalse: setEditModalFalse }] = useBoolean(false);

  const pageChange = (currentPage, pageSize) => {
    setPageState({
      ...pageState,
      currentPage,
      limit: pageSize,
    });
  };

  const [stateSelection, send] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        const res = await getSpecificationList(event.year);
        const { data, isSuccess, systemCode, message } = res;
        const { bookMap = {}, eduMap = {}, subjectMap = {}, usetypeMap = {}, versionMap = {}, yearMap = {} } = data?.selection || {};

        return {
          isSuccess, systemCode, message,
          specList: data?.specList || [],
          educationOptions: setSelectOptions(eduMap) || [],
          yearOptions: setSelectOptions(yearMap) || [],
          usetypeOptions: setSelectOptions(usetypeMap) || [],
          bookMap,
          subjectMap,
          versionMap
        };
      },
    },
  });

  const [stateSpecificationImportedList, sendSpecificationImportedList] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        const res = await getSpecificationImportedList(event.year);
        const { data, isSuccess, systemCode, message } = res;
        const { generalAmount = 0, premiumAmount = 0, specification } = data;

        return {
          isSuccess, systemCode, message,
          specImportedList: specification || [],
          generalAmount,
          premiumAmount
        };
      },
    },
  });

  const [stateSpecificationImport, sendSpecificationImport] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        const res = await postSpecificationImport(event.payload);
        const { isSuccess, systemCode, message } = res;
        unSelectAll();
        if (isSuccess) {
          openNotificationWithIcon('success', '匯入成功');
          sendSpecificationImportedList(FETCH_DATA_EVENT.FETCH, {
            year: importedYear
          });
        }
        setImportSecModalFalse();
        setImportModalFalse();
        setOnlyShowCheckedFalse();
        return {
          isSuccess, systemCode, message
        };
      }
    }
  });

  const [stateSpecificationRelated, sendSpecificationRelated] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        const res = await getSpecificationRelated(event.payload);
        const { isSuccess, systemCode, message, data } = res;
        const { useMap = [] } = data || {};
        return {
          isSuccess, systemCode, message,
          useOptions: setSelectOptions(useMap) || []
        };
      }
    }
  });

  const [stateSpecificationEdit, sendSpecificationEdit] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        const res = await putSpecificationEdit(event.payload);
        const { isSuccess, systemCode, message } = res;
        if (isSuccess) {
          openNotificationWithIcon('success', '編輯成功');
          sendSpecificationImportedList(FETCH_DATA_EVENT.FETCH, {
            year: importedYear
          });
        }
        setEditModalFalse();
        return {
          isSuccess, systemCode, message
        };
      }
    }
  });



  const { specList, educationOptions, yearOptions, usetypeOptions, bookMap, subjectMap, versionMap } = stateSelection.context.result || {};
  const { generalAmount, premiumAmount, specImportedList } = stateSpecificationImportedList.context.result || {};
  const { useOptions } = stateSpecificationRelated.context.result || {};

  const importedBookOptions = useMemo(() => {

    if (!bookMap || !importedFilterParams.education) return [];
    return setSelectOptions(bookMap[importedFilterParams.education]);
  }, [importedFilterParams.education]);
  const importedSubjectOptions = useMemo(() => {
    if (!subjectMap || !importedFilterParams.education) return [];
    return setSelectOptions(subjectMap[importedFilterParams.education]);
  }, [importedFilterParams.education]);
  const importedVersionOptions = useMemo(() => {
    if (!versionMap || !importedFilterParams.education) return [];
    return setSelectOptions(versionMap[importedFilterParams.education]);
  }, [importedFilterParams.education]);

  const bookOptions = useMemo(() => {

    if (!bookMap || !selectParams.education) return [];
    return setSelectOptions(bookMap[selectParams.education]);
  }, [selectParams.education]);
  const subjectOptions = useMemo(() => {
    if (!subjectMap || !selectParams.education) return [];
    return setSelectOptions(subjectMap[selectParams.education]);
  }, [selectParams.education]);
  const versionOptions = useMemo(() => {
    if (!versionMap || !selectParams.education) return [];
    return setSelectOptions(versionMap[selectParams.education]);
  }, [selectParams.education]);

  const filterImportedSpecList = useMemo(() => {
    if (!specImportedList) return [];
    const nextData = specImportedList.filter((item) => {
      const checkResult = Object.entries(importedFilterParams).map(([key, value]) => {
        if (value && value.length === 0) return true;
        if (key === 'books') {
          return intersectionArray(item[key], value);
        } else if (key === 'name') {
          return search(value, item[key]);
        } else {
          return intersection(item[key], value);
        }
      });
      const checkResultSet = new Set(checkResult);
      if (!checkResultSet.has(false)) return item;
    });
    return nextData;
  }, [selectParams, specImportedList]);

  const filterSpecList = useMemo(() => {
    if (!specList) return [];
    const nextData = specList.filter(ele => onlyShowNotImported ? !ele.isImport : true).filter((item) => {
      const checkResult = Object.entries(selectParams).map(([key, value]) => {
        if (value && value.length === 0) return true;
        if (key === 'books') {
          return intersectionArray(item[key], value);
        } else if (key === 'name') {
          return search(value, item[key]);
        } else {
          return intersection(item[key], value);
        }
      });
      const checkResultSet = new Set(checkResult);
      if (!checkResultSet.has(false)) return item;
    });
    return nextData;
  }, [selectParams, specList, onlyShowNotImported, onlyShowChecked]);

  const notImportedList = useMemo(() => {
    if (!filterSpecList) return [];
    return filterSpecList.filter(item => !item.isImport).map(ele => ele.uid);
  }, [filterSpecList]);

  const {
    selected,
    allSelected,
    isSelected,
    toggle,
    toggleAll,
    partiallySelected,
    unSelectAll
  } = useSelections(notImportedList, []);


  const onSearch = (val) => {
    setSelectParams({
      ...selectParams,
      name: val,
    });
  };

  const showImported = () => {
    send(FETCH_DATA_EVENT.FETCH, {
      year
    });
    setImportModalToggle();
  };

  const doImportSpec = () => {
    sendSpecificationImport(FETCH_DATA_EVENT.FETCH, {
      payload: {
        year,
        specUIDs: selected,
        usetype: importType
      }
    });
  };

  const showEdit = (uid, useType, year) => {
    setEditParams({
      uid,
      year,
    });
    setEditUseType(useType);
    setEditModalToggle();
    sendSpecificationRelated(FETCH_DATA_EVENT.FETCH);
  };

  const doEditSpec = () => {
    sendSpecificationEdit(FETCH_DATA_EVENT.FETCH, {
      payload: { ...editParams, useType: editUseType }
    });
  };

  const onYearChange = (year, type = '') => {
    if (type === 'imported') {
      setImportedYear(year);
      sendSpecificationImportedList(FETCH_DATA_EVENT.FETCH, {
        year
      });
      setImportedFilterParams({
        ...importedFilterParams,
        books: [],
        version: [],
        subject: [],
        usetype: [],
      });
    } else {
      setYear(year);
      send(FETCH_DATA_EVENT.FETCH, {
        year
      });
      setSelectParams({
        ...selectParams,
        books: [],
        version: [],
        subject: [],
        usetype: [],
      });
    }

  };

  const onEduChange = (edu, type = '') => {
    if (type === 'imported') {
      setImportedFilterParams({
        ...importedFilterParams,
        education: edu === '' ? [] : [edu],
        books: [],
        version: [],
        subject: [],
        usetype: [],
      });
    }
    setSelectParams({
      ...selectParams,
      education: edu === '' ? [] : [edu],
      books: [],
      version: [],
      subject: [],
      usetype: [],
    });
  };

  const onSelectChange = (type, value, listType = '') => {
    if (listType === 'imported') {
      setImportedFilterParams({
        ...importedFilterParams,
        [type]: value,
      });
    } else {
      setSelectParams({
        ...selectParams,
        [type]: value,
      });
    }
  };
  // 時間字串 切割
  const sliceTime = (time) => {
    return time ? `${time.slice(0, 10)} ${time.slice(11, 19)}` : '';
  };
  // 同步試題 API
  const [stateSpecificationRefresh, sendSpecificationRefresh] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(_context, event) => {
        const res = await putSpecificationRefresh(event.payload);
        const { data, isSuccess, systemCode, message } = res;

        return {
          isSuccess, systemCode, message,
        };
      },
    },
  });
  // 執行 同步試題
  const doRefresh = (item) => {
    item.isClicked = true;
    sendSpecificationRefresh(FETCH_DATA_EVENT.FETCH, {
      payload: {
        UID: item.uid,
        year: item.year
      }
    });
  };

  useEffect(() => {
    send(FETCH_DATA_EVENT.FETCH, {
      year
    });
    sendSpecificationImportedList(FETCH_DATA_EVENT.FETCH, {
      year: importedYear
    });
  }, []);


  return (
    <StyledHomePage className={className} data-testid="HomePage" id="HomePage">
      <StyledHeader>
        <div className="title">匯入管理</div>
        <Button variant="brand2" onClick={showImported} ><PlusOutline />匯入規格</Button>
      </StyledHeader>
      <div className="count">已匯入泛用試題 {generalAmount || '--'} 項、專業試題 {premiumAmount || '--'} 項，共 {premiumAmount + generalAmount || '--'} 項</div>
      <StyledFilterBar>
        <Input.Search placeholder="輸入以搜尋規格名稱" onSearch={onSearch} />
        <PickListDropdown.SinglePickListDropdown
          defaultTitle="學年度"
          options={yearOptions || []}
          onClick={(value) => onYearChange(value, 'imported')}
          value={importedYear}
        />
        <PickListDropdown.SinglePickListDropdown
          defaultTitle="學制"
          options={educationOptions || []}
          onClick={(value) => onEduChange(value, 'imported')}
          value={importedFilterParams.education[0] || null}
          onPickListClear={() => {onEduChange('');}}
        />
        {
          importedSubjectOptions.length > 0 &&
          <PickListDropdown
            defaultTitle="科目"
            options={importedSubjectOptions || []}
            onChange={(value) => onSelectChange('subject', value, 'imported')}
            value={importedFilterParams.subject}
          />
        }
        {
          importedVersionOptions.length > 0 &&
          <PickListDropdown
            defaultTitle="版本"
            options={importedVersionOptions || []}
            onChange={(value) => onSelectChange('version', value, 'imported')}
            value={importedFilterParams.version}
          />
        }
        {
          importedBookOptions.length > 0 &&
          <PickListDropdown
            defaultTitle="冊次"
            options={importedBookOptions || []}
            onChange={(value) => onSelectChange('books', value, 'imported')}
            value={importedFilterParams.books}
          />
        }
        <PickListDropdown
          defaultTitle="使用範圍"
          options={usetypeOptions || []}
          onChange={(value) => onSelectChange('usetype', value, 'imported')}
          value={importedFilterParams.usetype}
        />
      </StyledFilterBar>
      <Table>
        <Header>
          <Row className="tableTitle">
            <Item minWidth={62} flex="0.75">學年度</Item>
            <Item minWidth={62} flex="0.75">學制</Item>
            <Item minWidth={110} flex="1.67">科目</Item>
            <Item minWidth={70} flex="1">版本</Item>
            <Item minWidth={195} flex="4">規格名稱</Item>
            <Item minWidth={240} flex="5">冊次</Item>
            <Item minWidth={90} flex="1.47">使用範圍</Item>
            <Item minWidth={90} flex="1.47">題數</Item>
            <Item minWidth={180} flex="3">
              上次更新時間
              <Tooltip placement="top" title={'若規格內冊次上次更新時間不同，以最久未更新冊次的時間為顯示'}>
                <div className="toolTipIcon">?</div>
              </Tooltip>
            </Item>
            <Item minWidth={180} flex="3">
              預計下次更新時間
              <Tooltip placement="top" title={'若規格內冊次預計更新時間不同，以最先將更新冊次的時間為顯示'}>
                <div className="toolTipIcon">?</div>
              </Tooltip>
            </Item>
            <Item minWidth={130} flex="1.47" isStickyRight62={true} className='boxShadowLeft'>同步試題</Item>
            <Item minWidth={62} flex="0.75" isStickyRight0={true}>編輯</Item>
          </Row>
        </Header>
        <Body className="tableBody">
          {
            filterImportedSpecList &&
            filterImportedSpecList.length > 0 ?
              sliceTableData(filterImportedSpecList, pageState.currentPage, pageState.limit).map((item, index) => {
                return (
                  <Row key={index}>
                    <Item minWidth={62} flex="0.75">{item.year}</Item>
                    <Item minWidth={62} flex="0.75">{item.educationName}</Item>
                    <Item minWidth={110}flex="1.67">{item.subjectName}</Item>
                    <Item minWidth={70}flex="1">{item.versionName}</Item>
                    <Item minWidth={195} flex="4">{item.name}</Item>
                    <Item minWidth={240} flex="5">{item.bookNames}</Item>
                    <Item minWidth={90} flex="1.47">{item.usetypeName}</Item>
                    <Item minWidth={90} flex="1.47">{item.questionAmount}</Item>
                    <Item minWidth={180} flex="3">{sliceTime(item.lastRefresh)}</Item>
                    <Item minWidth={180} flex="3">{sliceTime(item.nextRefresh)}</Item>
                    <Item minWidth={130} flex="1.47" isStickyRight62={true} className='boxShadowLeft'>
                      {
                        item.isRefreshing ?
                          <Button className="refreshBtn isRefresh" variant="brand2" disabled={true}>
                            <SyncAltSolid className="refreshIcon"/>同步中
                          </Button>
                          : item?.isClicked ?
                            <Tooltip placement="top" title={'已將此規格排入更新試題清單，請稍後再檢視。'}>
                              <div className="refreshBtn waitingRefresh">
                                等待同步中
                              </div>
                            </Tooltip>
                            :
                            <Button className="refreshBtn" variant="brand2" onClick={() => {doRefresh(item);}}>
                              同步試題
                            </Button>
                      }
                    </Item>
                    <Item minWidth={62} flex="0.75" isStickyRight0={true} textalign={'center'}>
                      <EditSolid className="clickable" onClick={() => {showEdit(item.uid, item.usetype, item.year);}} />
                    </Item>
                  </Row>
                );
              }) : <div className="emptyMsg">沒有符合您搜尋的項目</div>
          }
        </Body>
      </Table>
      <div className="pageBlock">
        <Pagination
          total={filterImportedSpecList?.length || 0}
          defaultCurrent={1}
          current={pageState.currentPage}
          onChange={pageChange}
          defaultPageSize={pageState.limit}
        />
      </div>

      <Modal
        visible={importModalVisible}
        onCancel={setImportModalFalse}
        getContainer={document.getElementById('HomePage')}
        width="90%"
        title={'匯入規格'}
        footer={
          <>
            <Button className='button' variant='ui01'>取消</Button>
            <Button className='button' disabled={selected.length === 0} variant={selected.length === 0 ? 'ui02' : 'brand2'} onClick={setImportSecModalToggle}>確認</Button>
          </>
        }
      >
        <StyledFlexBox>
          <Box mb={6}>
            <div className="label">第一步｜選擇要匯入的使用範圍</div>
            <div>
              <StyledSelectBtn
                active={importType === 'General'}
                onClick={() => {
                  setImportType('General');
                }}
              >
                  泛用試題
              </StyledSelectBtn>
              <StyledSelectBtn
                active={importType === 'Premium'}
                onClick={() => {
                  setImportType('Premium');
                }}
              >
                  專業試題
              </StyledSelectBtn>
            </div>
          </Box>
        </StyledFlexBox>
        <StyledFlexBox>
          <Box my={6}>
            <div className="label">第二步｜選擇要匯入的規格</div>
            <div className="choiceBar">
              已選取 {selected.length} 項
              <CloseableTag variant={onlyShowChecked ? 'brand2' : 'default'} onClick={setOnlyShowCheckedToggle} onClose={setOnlyShowCheckedFalse}>查看已選取項目</CloseableTag>
            </div>
          </Box>
        </StyledFlexBox>


        <StyledFilterBar>
          <Input.Search placeholder="輸入以搜尋規格名稱" onSearch={onSearch} />
          <PickListDropdown.SinglePickListDropdown
            defaultTitle="學年度"
            options={yearOptions || []}
            onClick={(value) => onYearChange(value)}
            value={year}
          />
          <PickListDropdown.SinglePickListDropdown
            defaultTitle="學制"
            options={educationOptions || []}
            onClick={(value) => onEduChange(value)}
            value={selectParams.education[0] || null}
            onPickListClear={() => {onEduChange('');}}
          />
          {
            subjectOptions.length > 0 &&
          <PickListDropdown
            defaultTitle="科目"
            options={subjectOptions || []}
            onChange={(value) => onSelectChange('subject', value)}
            value={selectParams.subject}
          />
          }
          {
            versionOptions.length > 0 &&
          <PickListDropdown
            defaultTitle="版本"
            options={versionOptions || []}
            onChange={(value) => onSelectChange('version', value)}
            value={selectParams.version}
          />
          }
          {
            bookOptions.length > 0 &&
          <PickListDropdown
            defaultTitle="冊次"
            options={bookOptions || []}
            onChange={(value) => onSelectChange('books', value)}
            value={selectParams.books}
          />
          }
          <Checkbox checked={onlyShowNotImported} onClick={() => {setOnlyShowNotImported(!onlyShowNotImported);}}>只顯示未匯入過的項目</Checkbox>
        </StyledFilterBar>
        <Table>
          <Header>
            <Row className="tableTitle">
              <Item flex="0.5"><Checkbox checked={allSelected} onClick={toggleAll} indeterminate={partiallySelected}/></Item>
              <Item flex="0.75">學年度</Item>
              <Item flex="0.5">學制</Item>
              <Item flex="1.5">科目</Item>
              <Item flex="0.75">版本</Item>
              <Item flex="3.25">規格名稱</Item>
              <Item flex="4.75">冊次</Item>
              <Item flex="2">匯入狀況</Item>
            </Row>
          </Header>
          <Body>
            {
              filterSpecList &&
              filterSpecList.length > 0 ?
                sliceTableData(filterSpecList.filter(item => onlyShowChecked ? intersection(item.uid, selected) : true), pageState.currentPage, pageState.limit).map((item, index) => {
                  return (
                    <Row key={index} disabled={item.isImport}>
                      <Item flex="0.5"><Checkbox disabled={item.isImport} checked={isSelected(item.uid)} onClick={() => toggle(item.uid)}/></Item>
                      <Item flex="0.75">{item.year}</Item>
                      <Item flex="0.5">{item.educationName}</Item>
                      <Item flex="1.5">{item.subjectName}</Item>
                      <Item flex="0.75">{item.versionName}</Item>
                      <Item flex="3.25">{item.name}</Item>
                      <Item flex="4.75">{item.bookNames}</Item>
                      <Item flex="2">{item.isImport ? `已匯入為${item.usetypeName}試題` : '未匯入'}</Item>
                    </Row>
                  );
                }) : <div className="emptyMsg">沒有符合您搜尋的項目</div>
            }
          </Body>
        </Table>
        <div className="pageBlock">
          <Pagination
            total={filterSpecList?.length || 0}
            defaultCurrent={1}
            current={pageState.currentPage}
            onChange={pageChange}
            defaultPageSize={pageState.limit}
          />
        </div>
      </Modal>
      <Modal
        visible={importSecModalVisible}
        zIndex={1001}
        onCancel={setImportSecModalFalse}
        title={`確定要將 ${selected.length} 項規格匯入為${importType === 'General' ? '泛用' : '專業'}試題？`}
        footer={
          <>
            <Button variant='ui01' onClick={setImportSecModalFalse}>取消</Button>
            <Button isLoading={stateSpecificationImport.context.isLoading} variant={'brand1'} onClick={doImportSpec}>確定匯入</Button>
          </>
        }
      >
        <div>匯入後將無法自行移除。</div>
        <div>匯入後可再變更使用範圍為泛用試題或專業試題。</div>
      </Modal>

      <Modal
        visible={editModalVisible}
        zIndex={1001}
        onCancel={setEditModalFalse}
        title='編輯'
        footer={
          <>
            <Button className='button' variant='ui01' onClick={setEditModalFalse}>取消</Button>
            <Button className='button' isLoading={stateSpecificationEdit.context.isLoading} variant={'brand2'} onClick={doEditSpec}>確定</Button>
          </>
        }
      >
        <div className="useTypeArea">使用範圍
          {
            useOptions && useOptions.length > 0 ?
              <Select allowClear={false} options={useOptions} value={editUseType} onChange={(val) => {setEditUseType(val);}} /> : ''
          }

        </div>
      </Modal>
    </StyledHomePage>
  );
};