import { Api } from '@oneclass/ui-components';
import { stringify } from 'query-string';

// 取得 錯誤回報列表
// export const getAnomalyList = async (year) => {
//   const response = await Api.get(`${process.env.REACT_APP_CMS_DOMAIN}/api/Anomaly`);
//   return response;
// };

// 更新 回報列表的狀態 （停用/不停用）
export const putAnomaly = async (payload) => {
  const response = await Api.put(`${process.env.REACT_APP_CMS_DOMAIN}/api/Anomaly`, payload);
  return response;
};
// 取得 停用試題列表
// export const getAnomalyDisabledList = async () => {
//   const response = await Api.get(`${process.env.REACT_APP_CMS_DOMAIN}/api/Anomaly/Disabled`);
//   return response;
// };

// 已停用之試題 進行 啟用 or 新增停用試題
// export const postDisableQuestion = async (payload) => {
//   const response = await Api.post(`${process.env.REACT_APP_CMS_DOMAIN}/api/Anomaly/Disabled`, payload);
//   return response;
// };
// 新增停用試題 - 取得 學制／科目 或者 試題資訊
export const getAddDisableQuestionRelated = async (payload) => {
  const response = await Api.get(`${process.env.REACT_APP_CMS_DOMAIN}/api/Anomaly/Disabled/Related`, payload);
  return response;
};

// 更新 - 新ＡＰＩ

// 取得 所有學制科目列表
export const getAnomalyDisabledQueryEduSubject = async (payload) => {
  const response = await Api.get(`${process.env.REACT_APP_CMS_DOMAIN}/api/Anomaly/Disabled/QueryEduSubject`);
  return response;
};

// 查詢 單一試題 判斷是否存在(取回相關資料)
export const getAnomalyDisabledEduSubject = async (payload) => {
  const response = await Api.get(`${process.env.REACT_APP_CMS_DOMAIN}/api/Anomaly/Disabled/EduSubject`, payload);
  return response;
};

// 停用試題
export const postQuestionDisable = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Question/Disable`, payload);
  return response;
};

// 取得 錯誤回報列表
export const getReportList = async (year) => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Question/GetReports`);
  return response;
};

// 取得 停用試題列表
export const getDisabledList = async () => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Question/GetDisables`);
  return response;
};

// 回報題目
export const postQuestionReport = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Question/Report`, payload);
  return response;
};
