import styled from 'styled-components';

export const StyledMainLayout = styled.div`
/* Code Here */
    .sidebarList{
        svg{
                margin-right:16px;
                font-size: 22px;
        }   
    }

    .rightIcon{
        margin-right:0px;
        margin-left: 8px
    }

    .sidebarTitle{
        font-size: 18px;
        margin-bottom: 40px;
    }
    .mainLayoutContent{
        padding:32px 64px;
    }
    .sidebar{
        min-width: 220px;
    }
`;


export const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    flex:1;
    height: 100%;
    .logo{
        display: flex;
        align-items: center;
        img{
            width: 75%;
        }
    }
    .userAvatar{
        height: 100%;
        display: flex;
        align-items: center;
        transition: .5s;
        padding: 8px 20px;
        transition: .5s;
        margin-right: 30px;
        :hover{
            background-color:${({ theme }) => theme.colors.scale.$brand01.$brand01_09};
        }
    }
`;
export const StyledLink = styled.div`
    &:not(:last-child){
        margin-bottom: 24px;
    }
    a{
        position: relative;
        display: flex;
        align-items: center;
        flex: 1;
        cursor: pointer;
        color: ${({ theme, active }) => active ? theme.colors.brand.brand02 : theme.colors.text.text_07};
        svg{
            color: ${({ theme, active }) => active ? theme.colors.brand.brand02 : theme.colors.ui.ui_05};
        }
    }
`;
export const StyledLinkGroup = styled.div`
    margin-bottom: 24px;
    >div:first-child{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: ${({ theme }) => theme.colors.text.text_07};
        cursor: pointer;
        >div{
            display: flex;
            align-items: center;
            >svg{
                color: ${({ theme }) => theme.colors.ui.ui_05};
            }
        }
        >svg{
            font-size: 17px;
            color: ${({ theme }) => theme.colors.ui.ui_05};
        }
    }
    >div:nth-child(2){
        margin-bottom: 24px;
        >div:not(:last-child){
            margin-bottom: 16px !important;
        }
        a{
            padding: 0 0 0 10px;
            /* position: relative;
            display: flex;
            align-items: center;
            flex: 1;
            cursor: pointer; */
            /* color: ${({ theme, active }) => active ? theme.colors.brand.brand02 : theme.colors.text.text_07}; */
            svg{
                margin-right: 12px !important;
                font-size: 16px !important;
                /* color: ${({ theme, active }) => active ? theme.colors.brand.brand02 : theme.colors.ui.ui_05}; */
            }
        }
    }
    .active{
        border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_03};
        padding: 0 0px 18px 0px;
        margin-bottom: 12px;

        >svg{
            transform: rotate(180deg);
        }
    }
`;
export const StyledNavigationBar = styled.div`
    display: flex;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    & > div{
        position: relative;
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: center;
        cursor: pointer;
        padding: 12px 0;
        color: ${({ theme, active }) => active ? theme.colors.scale.$brand01.$brand01_10 : theme.colors.text.text_06};
        :last-child{
            &::before{
                content: '';
                height: 50%;
                width: 1px;
                position: absolute;
                left: 0;
                top: 25%;
                background-color: ${({ theme }) => theme.colors.ui.ui_04};
            }
        }
    }
    .draw{
        position: relative;
        color: ${({ theme }) => theme.colors.text.white};
        background-color: ${({ theme }) => theme.colors.brand.brand02};
        border-radius: 8px 8px 0px 0px;
        padding: 0 0;
        .drawBtn{
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;
            height: 100%;
            padding: 12px 0;
        }
        .drawList{
            position: absolute;
            width: 125%;
            background-color: ${({ theme }) => theme.colors.ui.white};
            color: ${({ theme }) => theme.colors.text.black};
            top: 100%;
            left: 0;
            z-index: 10;
            padding: 16px;
            box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            .drawRow{
                .drawListTitle{
                    font-weight: 500;
                    font-size: 18px;
                }
                .drawItemList{
                    display: flex;
                    flex-wrap: wrap;
                }
            }
        }
    }
    
`;

export const StyledDrawItem = styled.div`
    margin:4px;
    padding: 4px;
    border-radius: 12px;
    color: ${({ theme, active }) => active ? theme.colors.text.white : theme.colors.text.black};
    background-color: ${({ theme, active }) => active ? theme.colors.brand.brand02 : theme.colors.ui.white};
    :hover{
        background: ${({ theme }) => theme.colors.ui.ui_02};
        color: ${({ theme }) => theme.colors.text.black};
    }
`;

export const UiUserBox = styled.div`
	display: flex;
	align-items: center;
	transition: 0.5s;

	img {
		overflow: hidden;
		margin-right: 5px;
		width: 24px;
		height: 24px;
		border-radius: 50%;
	}

	&:hover {
		color: #fff;
		opacity: 0.8;
	}
`;
