import React, { useEffect, useState, useRef } from 'react';
import { StyledInternalAccountPage, StyledTag, StyledConfirmContent, StyledEditModalContent } from './InternalAccountPage.style';
import { EditSolid } from '@onedesign/icon';
import { fetchDataMachine, FETCH_DATA_EVENT, Table, Modal, Pagination, sliceTableData, Switch, TagSelect } from '@oneclass/ui-components';
import { getMemberList, putCmsAuthorityControl, putAccountEdit, getTagsList } from 'api/member';
import { StyledHeader } from 'styles/index';
import { useMachine } from '@xstate/react';
import { Popover, Button } from '@oneclass/onedesign';
import { useBoolean } from 'ahooks';

const { Header, Body, Row, Item } = Table;

export const InternalAccountPage = ({ className }) => {

  const TagsBoxRef = useRef(null);

  // 將字串 逐字判斷類型(中文/英文數字) 轉換成長度
  const transformStringWidth = (String) => {
    if (!String) return 0;
    let chineseNum = 0;
    let englishNum = 0;
    let numberNum = 0;

    const chineseString = new RegExp('[\u4E00-\u9FA5]+');
    const englishString = new RegExp('[A-Za-z]+');
    const numberString = new RegExp('[0-9]+');

    const stringLength = String.length;
    for (let i = 0 ; i <= stringLength - 1 ; i++ ) {
      if (chineseString.test(String.charAt(i))) chineseNum++;
      if (englishString.test(String.charAt(i))) englishNum++;
      if (numberString.test(String.charAt(i))) numberNum++;
    }
    return chineseNum * 14 + (englishNum + numberNum) * 9.3515;
  };

  // 標籤列表 長度判斷 超過就轉換
  const filterTagsArray = (tagsArray) => {
    let useWidth = 0;
    let showTagsArray = [];
    let hideTagsArray = [];
    if (!tagsArray) return { showTagsArray, hideTagsArray };
    const TagsBoxWidth = TagsBoxRef.current.clientWidth - 70 - 30;
    tagsArray.forEach((tag, index) => {
      useWidth = useWidth + 8 * 2 + transformStringWidth(tag.name);
      if (useWidth > TagsBoxWidth) {
        hideTagsArray.push(tag);
      } else {
        showTagsArray.push(tag);
      }
    });
    return ({
      showTagsArray, hideTagsArray,
    });
  };

  // 取得 內部員工列表 API
  const [getAccountListState, getAccountListSend] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(context) => {
        const res = await getMemberList();
        const { data, isSuccess, systemCode, message } = res;
        return {
          isSuccess, systemCode, message,
          accountListData: data,
        };
      }
    }
  });
  const { accountListData } = getAccountListState.context.result || {};

  // 翻頁相關功能
  const [accountListPageState, setAccountListPageState] = useState({
    currentPage: 1,
    limit: 10,
  });

  const accountListPageChange = (currentPage, pageSize) => {
    setAccountListPageState({
      ...accountListPageState,
      currentPage,
      limit: pageSize,
    });
  };

  // 啟用/停用 後台管理權限 API
  const [CmsAuthorityControlState, CmsAuthorityControlSend] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(context, event) => {
        const res = await putCmsAuthorityControl(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        getAccountListSend(FETCH_DATA_EVENT.FETCH);
        setConfirmModalFalse();
        return {
          isSuccess, systemCode, message,
        };
      }
    }
  });

  // 執行 切換 後台管理權限
  const doSwitchCmsAuthorityControl = () => {
    CmsAuthorityControlSend(FETCH_DATA_EVENT.FETCH, {
      payload: {
        UID: confirmModalData.uid,
        isActive: !confirmModalData.allowCMS,
      }
    });
  };

  // 確認 提示視窗 顯示控制
  const [confirmModalVisible, { toggle: setConfirmModalToggle, setFalse: setConfirmModalFalse }] = useBoolean(false);

  const showConfirmModal = (UID, allowCMS) => {
    setConfirmModalToggle();
    // 切換彈窗內容
    allowCMS ? setConfirmModalData({
      title: '確定要關閉該帳號進入後台的權限？',
      contentText: '關閉後，該帳號將無法進入後台操作功能。\n可以再次開啟權限。',
      confirmBtnText: '確定關閉',
      uid: UID,
      allowCMS: allowCMS,
    }) : setConfirmModalData({
      title: '確定要開啟該帳號進入後台的權限？',
      contentText: '開啟後，該帳號將能夠進入後台操作功能。\n可以再次關閉權限。',
      confirmBtnText: '確定開啟',
      uid: UID,
      allowCMS: allowCMS,
    });
  };

  // 確認 提示視窗 內文
  const [confirmModalData, setConfirmModalData] = useState({
    title: '',
    contentText: '',
    confirmBtnText: '',
    uid: '',
    allowCMS: false,
  });

  // 轉換 tags 預設值
  const transformTagsData = (tags) => {
    return tags.map((tag) => {
      let uid = '';
      if (tagSelectOptions.length > 0) {
        tagSelectOptions.forEach((option) => {
          if (option.name === tag.name) {
            uid = option.uid;
          }
        });

      }
      return uid;
    });
  };

  // Click 編輯按鈕
  const onEditBtnClick = (item) => {
    setEditModalToggle();
    setEditModalData({
      account: item.account,
      name: item.name,
      tags: transformTagsData(item.tags),
      allowCMS: item.allowCMS,
      uid: item.uid,
    });
  };

  // 編輯 彈窗 顯示控制
  const [editModalVisible, { toggle: setEditModalToggle, setFalse: setEditModalFalse }] = useBoolean(false);

  // 編輯 彈窗 資料
  const [editModalData, setEditModalData] = useState({
    account: '',
    name: '',
    allowCMS: false,
    uid: '',
    tags: [],
  });

  // 變更 選擇 標籤
  const tagSelectChange = (value) => {
    setEditModalData({
      ...editModalData,
      tags: value,
    });
  };

  // 轉換 資料格式
  const tagsDataAddValue = (tags) => {
    return tags.map((tag) => {
      return { ...tag, value: tag.uid };
    });
  };

  // 取得 所有標籤 API
  const [getTagsListState, getTagsListStateSend] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(context) => {
        const res = await getTagsList();
        const { data, isSuccess, systemCode, message } = res;
        return {
          isSuccess, systemCode, message,
          tagSelectOptions: tagsDataAddValue(data.tags),
        };
      }
    }
  });

  const { tagSelectOptions } = getTagsListState.context.result || [];

  // 編輯權限 & 標籤設定  API
  const [accountEditState, accountEditSend] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(context, event) => {
        const res = await putAccountEdit(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        setEditModalFalse();
        getAccountListSend(FETCH_DATA_EVENT.FETCH);
        getTagsListStateSend(FETCH_DATA_EVENT.FETCH);
        return {
          isSuccess, systemCode, message,
        };
      }
    }
  });

  const doAccountEdit = () => {
    accountEditSend(FETCH_DATA_EVENT.FETCH, {
      payload: {
        uid: editModalData.uid,
        tags: editModalData.tags,
        allowCMS: editModalData.allowCMS,
      }
    });
  };

  // 當 編輯視窗關閉時 清除內容，開啟時 打api 取得 所有標籤
  useEffect(() => {
    if (editModalVisible === false) {
      setEditModalData({
        account: '',
        name: '',
        allowCMS: false,
        uid: '',
        tags: [],
      });
    }
  }, [editModalVisible]);

  // 開啟本頁面時 打api 取得 內部帳號資料
  useEffect(() => {
    getAccountListSend(FETCH_DATA_EVENT.FETCH);
    getTagsListStateSend(FETCH_DATA_EVENT.FETCH);
  }, []);

  return (
    <StyledInternalAccountPage className={className} data-testid="InternalAccountPage" id="InternalAccountPage">
      <StyledHeader>
        <div className="title">內部帳號管理</div>
      </StyledHeader>
      <Table>
        <Header>
          <Row className="tableRowMinHeight">
            <Item flex={1}>帳號</Item>
            <Item flex={1}>名稱</Item>
            <Item flex={3} antRef={TagsBoxRef}>標籤</Item>
            <Item flex={0.625} textalign={'center'}>後台權限</Item>
            <Item flex={0.35} textalign={'center'}>編輯</Item>
          </Row>
        </Header>
        <Body>
          {
            accountListData &&
            sliceTableData(accountListData, accountListPageState.currentPage, accountListPageState.limit).map((item, index) => {
              return (
                <Row key={index} className="tableRowMinHeight">
                  <Item flex={1}>{item.account}</Item>
                  <Item flex={1}>{item.name}</Item>
                  <Item flex={3} className={'TagsBox'} >
                    {
                      filterTagsArray(item.tags)?.showTagsArray?.length > 0 &&
                        filterTagsArray(item.tags)?.showTagsArray.map((tag, index) => {
                          return (
                            <StyledTag className={'tag'} key={index}>
                              {tag.name}
                            </StyledTag>
                          );
                        })
                    }
                    {
                      filterTagsArray(item.tags)?.hideTagsArray?.length > 0 &&
                        <Popover
                          className={'tagsHover'}
                          content={filterTagsArray(item.tags)?.hideTagsArray.map((tag, index) => {
                            return (
                              <StyledTag className={'tag'} key={index}>
                                {tag.name}
                              </StyledTag>
                            );
                          })}>
                          + {filterTagsArray(item.tags)?.hideTagsArray?.length}
                        </Popover>
                    }
                  </Item>
                  <Item flex={0.625} textalign={'center'}>
                    <Switch key={item.uid} checked={item.allowCMS} onClick={() => {showConfirmModal(item.uid, item.allowCMS);}}/>
                  </Item>
                  <Item flex={0.35} textalign={'center'} className={'editBtnBox'}>
                    <EditSolid onClick={() => {onEditBtnClick(item);}}/>
                  </Item>
                </Row>
              );
            })
          }
        </Body>
        <div className="pageBlock">
          <Pagination
            total={accountListData?.length || 0}
            defaultCurrent={1}
            current={accountListPageState.currentPage}
            onChange={accountListPageChange}
            defaultPageSize={accountListPageState.limit}
          />
        </div>
      </Table>

      {/*  ---- 確認 開啟/關閉 後台權限 彈窗  ---- */}
      <Modal
        visible={confirmModalVisible}
        onCancel={setConfirmModalFalse}
        getContainer={document.getElementById('InternalAccountPage')}
        width="40%"
        title={confirmModalData.title}
        footer={
          <>
            <Button className='button' variant='ui01' onClick={() => {setConfirmModalFalse();}}>取消</Button>
            <Button className='button' variant="brand2" onClick={() => {doSwitchCmsAuthorityControl();}}>{confirmModalData.confirmBtnText}</Button>
          </>
        }
      >
        <StyledConfirmContent>
          {confirmModalData.contentText}
        </StyledConfirmContent>
      </Modal>

      {/* ----- 編輯 彈窗 ----- */}
      <Modal
        visible={editModalVisible}
        onCancel={setEditModalFalse}
        getContainer={document.getElementById('InternalAccountPage')}
        width="40%"
        title={'編輯內部帳號'}
        footer={
          <>
            <Button className='button' variant='ui01' onClick={() => {setEditModalFalse();}}>取消</Button>
            <Button className='button' variant="brand2" onClick={() => {doAccountEdit();}}>確定</Button>
          </>
        }
      >
        <StyledEditModalContent>
          <div>
            <div>帳號</div>
            <div>{editModalData.account}</div>
          </div>
          <div>
            <div>名稱</div>
            <div>{editModalData.name}</div>
          </div>
          <div id='tagSelect'>
            <div>標籤</div>
            <TagSelect
              mode={'multiple'}
              options={tagSelectOptions}
              defaultValue={editModalData.tags}
              key={editModalData.uid}           // 超級雷點 缺少此key值 不會重新渲染
              onChange={tagSelectChange}
              placeholder="點擊選擇標籤"
              getPopupContainer={() => document.getElementById('tagSelect')}
            />
          </div>
          <div>
            <div>後台權限</div>
            <div>
              <div>
                <Switch
                  checked={editModalData.allowCMS}
                  onChange={() => {setEditModalData({
                    ...editModalData,
                    allowCMS: !editModalData.allowCMS,
                  });}}/><span>{editModalData.allowCMS ? '開啟' : '關閉'}</span>
              </div>
              <div>該帳號將{editModalData.allowCMS ? '能夠' : '無法'}進入後台操作功能。</div>
            </div>
          </div>
        </StyledEditModalContent>
      </Modal>
    </StyledInternalAccountPage>
  );
};
