import { Api } from '@oneclass/ui-components';
import { stringify } from 'query-string';
// export const getAuthorityRoleDefault = async () => {
// 	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Authority/RoleDefault`)
// 		.then((res) => {
// 			return res;
// 		})
// 		.catch((error) => {
// 			return {
// 				isSuccess: false,
// 				message: error.data,
// 			};
// 		});
// 	return response;
// };

export const getSpecificationList = async (year) => {
  const response = await Api.get(`${process.env.REACT_APP_CMS_DOMAIN}/api/Specification/${year}`);
  return response;
};

export const getSpecificationImportedList = async (year) => {
  const queryString = stringify({ year });
  const response = await Api.get(`${process.env.REACT_APP_CMS_DOMAIN}/api/Specification/Imported?${queryString}`);
  return response;
};

export const postSpecificationImport = async (payload) => {
  const response = await Api.post(`${process.env.REACT_APP_CMS_DOMAIN}/api/Specification/Import`, payload);
  return response;
};

export const getSpecificationRelated = async () => {
  const response = await Api.get(`${process.env.REACT_APP_CMS_DOMAIN}/api/Specification/Related`);
  return response;
};

export const putSpecificationEdit = async (payload) => {
  const response = await Api.put(`${process.env.REACT_APP_CMS_DOMAIN}/api/Specification/Edit`, payload);
  return response;
};
// 同步試題
export const putSpecificationRefresh = async (payload) => {
  const response = await Api.put(`${process.env.REACT_APP_CMS_DOMAIN}/api/Refresh/Specification/${payload.UID}?year=${payload.year}`, payload);
  return response;
};