import { assign, createMachine } from 'xstate';
import openNotificationWithIcon from '../utils/hooks/useNotification';
export const FETCH_DATA_STATE = {
  IDLE: 'IDLE',
  FETCHING: 'FETCHING',
  DONE: 'DONE'
};

export const FETCH_DATA_EVENT = {
  FETCH: 'FETCH',
  CANCEL: 'CANCEL',
  RECEIVE_DATA: 'RECEIVE_DATA'
};

export const systemCode = {
  '0201': 'Insert Failed',
  '0211': '更新失敗！',
  '0221': '下載失敗！',
  '0222': '解析失敗！',
  '0223': '檢查失敗！',
  '0224': '匯入失敗！',
  '0400': '資料不存在！',
  '0401': '資料已經存在！',
  '0491': 'Code 已經存在',
  '0492': '名稱已重複！',
  '0493': 'Parent Code 不存在！',
  '0494': 'Code 不存在！',
  '0495': 'UID 不存在！',
  '0496': 'Type or Attribute 不存在！',
  '0497': 'Code 不存在！',
  '0498': 'Data 不允許',
  9999: '例外總是來得這麼突然',
};

const fetchDataMachine = createMachine(
  {
    id: 'fetchData',
    initial: FETCH_DATA_STATE.IDLE,
    states: {
      [FETCH_DATA_STATE.IDLE]: {
        on: {
          FETCH: {
            target: FETCH_DATA_STATE.FETCHING,
          },
        },
        initial: 'noError',
        states: {
          noError: {
            entry: ['clearErrorMessage'],
          },
          errored: {},
        },
      },
      [FETCH_DATA_STATE.FETCHING]: {
        entry: ['assignFetchingData'],
        invoke: {
          src: 'fetchData',
          onDone: {
            actions: 'assignDataToContext',
            target: FETCH_DATA_STATE.DONE
          },
          onError: {
            target: 'IDLE.errored',
            actions: 'assignErrorToContext',
          },
        },
      },
      [FETCH_DATA_STATE.DONE]: {
        on: {
          [FETCH_DATA_EVENT.FETCH]: {
            target: FETCH_DATA_STATE.FETCHING,
          },
          [FETCH_DATA_EVENT.CANCEL]: {
            target: FETCH_DATA_STATE.IDLE,
          },
          [FETCH_DATA_EVENT.RECEIVE_DATA]: {
            target: FETCH_DATA_STATE.IDLE,
            actions: 'assignDataToContext',
          },
        },
      }
    },
  },
  {
    actions: {
      assignDataToContext: assign((context, event) => {
        if (event.type === FETCH_DATA_EVENT.RECEIVE_DATA) return {};
        if (event.data?.isSuccess === false) {
          openNotificationWithIcon('error', event.data.message || systemCode[event.data.systemCode] || '');
        }
        return {
          result: event.data,
          isLoading: false,
        };
      }),
      assignFetchingData: assign({
        isLoading: true,
      }),
      clearErrorMessage: assign({
        errorMessage: undefined,
        isLoading: false,
      }),
      assignErrorToContext: assign((context, event) => {
        return {
          errorMessage: event?.message || 'An unknown error occurred',
          isLoading: false,
        };
      }),
    },
  },
);

export default fetchDataMachine;
