import styled from 'styled-components';

export const StyledInternalAccountPage = styled.div`
    .title{
        margin-bottom: 32px;
    }
    .tableRowMinHeight{
        min-height: 48px;
    }
    .tableRowMinHeight:not(:first-child){
        >div{
            border-bottom: 1px solid #f0f0f0 !important;
        }
    }
    .editBtnBox{
        >svg{
            cursor: pointer;
            font-size: 24px;
        }
    }
    .pageBlock {
		margin-top: 56px;
	}
    .TagsBox{
        >span{
            cursor: pointer;
            background-color: ${({ theme }) => theme.colors.brand.brand03};
            color: #fff;
            padding: 2px 8px;
            border-radius: 12px;
            white-space: nowrap;
        }
    }
    .tagsHover{
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.scale.$brand03.$brand03_01};
        color:${({ theme }) => theme.colors.brand.brand03};
        padding: 4px 8px;
        border-radius: 4px;
    }
    .ant-popover-inner{
        background: #fff !important;
        padding: 16px 16px !important;
    }
    .ant-popover-inner-content{
        display: flex !important;
        flex-wrap: wrap !important;
        max-width: 378px !important;
        max-height: 176px !important;
        width: auto !important;
        height: auto !important;
        overflow: auto !important;

        >div{
            margin-bottom: 12px !important;
        }
    }
    .ant-select-selection-placeholder{
		color: #D5D7DE !important;
    }
    .ant-select-item-option{
        background-color:#fff !important;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
        background-color:rgba(250, 250, 252, 1) !important;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state{
        color:rgba(18, 18, 50, 1) !important;
    }
    .ant-select-item-option:hover{
        background-color:rgba(242, 244, 248, 1) !important;
    }
`;

export const StyledTag = styled.div`
    background-color: ${({ theme }) => theme.colors.scale.$brand03.$brand03_01};
    color:${({ theme }) => theme.colors.brand.brand03};
    padding: 4px 8px;
    border-radius: 4px;
    &:not(:last-child){
        margin-right: 12px;
    }
`;

export const StyledTooltip = styled.div`
   position: relative;
   &:hover >div{
    opacity: 1;
   }
   >div{
       position: absolute;
       opacity: 1;
       bottom: -50px;
       max-width: 378px;
       max-height: 176px;
       overflow-y: auto;
       border: 1px solid red;
   }
`;

export const StyledConfirmContent = styled.div`
	color: ${({ theme }) => theme.colors.text.text_06};
    white-space: pre;
`;

export const StyledEditModalContent = styled.div`
	>div{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0 0 20px 0;
        >div:first-child{
            margin-right: 10px;
            width: 96px;
		    color: ${({ theme }) => theme.colors.text.text_06};
            display: flex;
            align-items: center;
        }
        >div:nth-child(2){
            width: 100%;
        }
    }
    >div:nth-child(4){
        align-items: flex-start;
        >div:nth-child(2){
            display: flex;
            flex-direction: column;
            >div:first-child{
                display: flex;
                margin-bottom: 4px;
                align-items: center;
                >div:first-child{
                    margin-right: 12px;
                }
            }
            >div:nth-child(2){
		        color: ${({ theme }) => theme.colors.text.text_06};
            }
        }
    }
`;