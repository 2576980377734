import React from 'react';
import { PopoverProps as AntdPopoverProps } from 'antd';
import { StyledPopover } from './Popover.style';

export interface PopoverProps extends AntdPopoverProps {
  arrow?: boolean
}

export const Popover = (props: PopoverProps) => {

  const {
    children,
    title,
    content,
    trigger,
    placement,
    visible,
    arrow = false,
    onVisibleChange,
  } = props;

  return (
    <StyledPopover
      arrow={arrow}
      placement={placement}
      title={title}
      content={content}
      trigger={trigger}
      visible={visible}
      getPopupContainer={(triggerNode: HTMLElement) => triggerNode}
      onVisibleChange={onVisibleChange}
      data-testid="Popover"
    >
      {children}
    </StyledPopover>
  );
};