import React, { useEffect, useMemo, useState } from 'react';
import { fetchDataMachine, FETCH_DATA_EVENT, PickListDropdown, setSelectOptions, Table, Tab, TabPane, Modal, Input, Pagination, sliceTableData, openNotificationWithIcon } from '@oneclass/ui-components';
import { Button } from '@oneclass/onedesign';
import { PlusOutline, CheckCircleSolid, ExclamationCircleSolid } from '@onedesign/icon';
import { getReportList, putAnomaly, getDisabledList, postQuestionReport, getAddDisableQuestionRelated, getAnomalyDisabledQueryEduSubject, getAnomalyDisabledEduSubject, postQuestionDisable } from 'api/anomaly';
import { StyledHeader, StyledFilterBar } from 'styles/index';
import { StyledQuestionDisablePage, StyledControlBtn, StyledDetailHeaderBox, StyledDetailImageBox, StyledCountBox, StyledDetailTableBox, StyledConfirmContent, StyledAddDisableContent } from './QuestionDisablePage.style';
import { useMachine } from '@xstate/react';
import { useBoolean, useSelections } from 'ahooks';
import classNames from 'classnames';

const { Header, Body, Row, Item } = Table;

export const QuestionDisablePage = ({ className }) => {

  const [filter, setFilter] = useState({
    education: '',
    subject: '',
  });
  const [anomalyFilter, setAnomalyFilter] = useState({
    education: '',
    subject: '',
  });

  const [addDisableSelectParams, setAddDisableSelectParams] = useState({
    education: '',
    subject: '',
  });

  const [searchData, setSearchData] = useState({
    education: '',
    subject: '',
    uid: '',
    image: '',
    description: '',
  });
  // 比對結果 (輸入的UID 是否存在於 撈回資料之中)
  const [comparisonResult, setComparisonResult] = useState(false);
  // 控制 搜尋 是否出現 loading圖示
  const [searchIsLoading, setSearchIsLoading] = useState(false);
  // 控制搜尋文字 結果是否顯示
  const [checkTextIsShow, setCheckTextIsShow] = useState(false);

  const searchDataCancelHandler = () => {
    setComparisonResult(false);
    setCheckTextIsShow(false);
    setSearchIsLoading(false);
    setImportAddDisableQuestionModalFalse();
    setAddDisableSelectParams({
      education: '',
      subject: '',
    });
    setSearchData({
      education: '',
      subject: '',
      uid: '',
      image: '',
      description: ','
    });
  };

  // 打API 取得 錯誤回報清單
  const [state, send] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(context, event) => {
        const res = await getReportList();
        const { data, isSuccess, systemCode, message } = res;
        // const { eduMap = {}, subjectMap = {}, typeMap = {}, recordGroup = [] } = data || {};
        return {
          isSuccess, systemCode, message,
          // educationOptions: setSelectOptions(eduMap) || [],
          // subjectOptions: reBuildSubjectOptions(subjectMap) || [],
          // typeOptions: setSelectOptions(typeMap) || [],
          recordGroup: reBuildRecordGroup(data) || [],
        };
      }
    }
  });

  // 打API 取得 停用試題清單
  const [disabledState, disabledSend] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(context, event) => {
        const res = await getDisabledList();
        const { data, isSuccess, systemCode, message } = res;
        // const { eduMap = {}, subjectMap = {}, typeMap = {}, recordGroup = [] } = data || {};
        return {
          isSuccess, systemCode, message,
          // disabledEducationOptions: setSelectOptions(eduMap) || [],
          // subjectOptions: setSelectOptions(subjectMap) || [],
          // disabledSubjectOptions: reBuildSubjectOptions(subjectMap) || [],
          // disabledTypeOptions: setSelectOptions(typeMap) || [],
          disabledRecordGroup: reBuildRecordGroup(data) || [],
        };
      }
    }
  });

  // 回報試題
  const [stateReportQuestion, sendReportQuestion] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        let res;
        res = await postQuestionReport(event.payload);
        const { isSuccess, systemCode, message } = res;
        if (isSuccess) {
          // openNotificationWithIcon('success', '匯入成功');
        }
        send(FETCH_DATA_EVENT.FETCH);
        disabledSend(FETCH_DATA_EVENT.FETCH);
        setImportDetailModalFalse();
        setImportConfirmVisibleModalFalse();
        searchDataCancelHandler();
        return {
          isSuccess, systemCode, message
        };
      }
    }
  });

  // 停用試題
  const [stateDisableQuestion, sendDisableQuestion] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async (_context, event) => {
        let res;
        res = await postQuestionDisable(event.payload);
        const { isSuccess, systemCode, message } = res;
        const { isDisabled } = event.payload;
        if (isSuccess) {
          openNotificationWithIcon('success', isDisabled ? '停用試題' : '取消停用');
        } else {
          openNotificationWithIcon('error', message);
        }
        send(FETCH_DATA_EVENT.FETCH);
        disabledSend(FETCH_DATA_EVENT.FETCH);
        setImportDetailModalFalse();
        setImportConfirmVisibleModalFalse();
        searchDataCancelHandler();
        return {
          isSuccess, systemCode, message
        };
      }
    }
  });

  // const doDisabledQuestion = () => {
  //   doControlQuestionSend(FETCH_DATA_EVENT.FETCH, {
  //     payload: {
  //       education: detailData.education,
  //       subject: detailData.subject,
  //       itemID: detailData.itemID,
  //       description: '',
  //       isDisabled: confirmText.isDisabled,
  //     }
  //   });
  // };

  const { recordGroup } = state.context.result || {};

  // const [educationOptions, setEducationOptions] = useState(([]));
  const educationOptions = useMemo(() => {
    if (!recordGroup) return [];
    let eduArray = [];
    recordGroup.forEach((item) => {
      if (eduArray.indexOf(item.education) === -1) {
        eduArray.push(item.education);
      }
    });
    let newData = [];
    eduArray.forEach((item) => {
      newData.push({
        name: item,
        value: item,
      });
    });
    return newData;
  }, [recordGroup]);

  // 將科目列表 重新整理
  const reBuildSubjectOptions = (subjectMap) => {
    const allSubjectOptions = [];
    for (const [key, value] of Object.entries(subjectMap)) {
      value.forEach((item) => {
        allSubjectOptions.push({
          eudCode: key,
          value: item.code,
          name: item.name
        });
      });
    }
    return allSubjectOptions;
  };

  const subjectOptions = useMemo(() => {
    if (!recordGroup) return [];
    let subjectObject = {};
    recordGroup.forEach((item) => {
      let edu = item.education;
      if (subjectObject?.edu) {
        subjectObject[edu].push({
          code: item.subject,
          name: item.subject,
        });
      } else {
        subjectObject = {
          ...subjectObject,
          [edu]: [{
            code: item.subject,
            name: item.subject,
          }]
        };
      }
    });
    return reBuildSubjectOptions(subjectObject);
  }, [recordGroup]);

  const { disabledRecordGroup } = disabledState.context.result || {};

  const disabledEducationOptions = useMemo(() => {
    if (!disabledRecordGroup) return [];
    let eduArray = [];
    disabledRecordGroup.forEach((item) => {
      if (eduArray.indexOf(item.education) === -1) {
        eduArray.push(item.education);
      }
    });
    let newData = [];
    eduArray.forEach((item) => {
      newData.push({
        name: item,
        value: item,
      });
    });
    return newData;
  }, [disabledRecordGroup]);

  const disabledSubjectOptions = useMemo(() => {
    if (!disabledRecordGroup) return [];
    let subjectObject = {};
    disabledRecordGroup.forEach((item) => {
      let edu = item.education;
      if (subjectObject?.edu) {
        subjectObject[edu].push({
          code: item.subject,
          name: item.subject,
        });
      } else {
        subjectObject = {
          ...subjectObject,
          [edu]: [{
            code: item.subject,
            name: item.subject,
          }]
        };
      }
    });
    return reBuildSubjectOptions(subjectObject);
  }, [disabledRecordGroup]);

  const [stateGetAnomalyDisabledQueryEduSubject, sendGetAnomalyDisabledQueryEduSubject] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(context, event) => {
        const res = await getAnomalyDisabledQueryEduSubject();
        const { data, isSuccess, systemCode, message } = res;
        const { eduMap = {}, subjectMap = {} } = data || {};
        return {
          isSuccess, systemCode, message,
          addDisabledEducationOptions: setSelectOptions(eduMap) || [],
          subjectMap,
        };
      }
    }
  });
  const { addDisabledEducationOptions, subjectMap: addDisableSubjectMap } = stateGetAnomalyDisabledQueryEduSubject.context.result || {};

  // 查詢 單一試題
  const [stateGetAnomalyDisabledEduSubject, sendGetAnomalyDisabledEduSubject] = useMachine(fetchDataMachine, {
    services: {
      fetchData: async(context, event) => {
        setSearchIsLoading(true);
        const res = await getAnomalyDisabledEduSubject(event.payload);
        const { data, isSuccess, systemCode, message } = res;
        setSearchIsLoading(false);
        if (isSuccess) {
          setCheckTextIsShow(false);
          setComparisonResult(true);
          setSearchData({
            ...searchData,
            image: data[0].image
          });
        } else {
          setComparisonResult(false);
          setCheckTextIsShow(true);
        }
        return {
          isSuccess, systemCode, message,
        };
      }
    }
  });

  const doSearch = () => {
    sendGetAnomalyDisabledEduSubject(FETCH_DATA_EVENT.FETCH, {
      payload: {
        eduSubject: searchData.education + searchData.subject,
        UID: searchData.uid,
      }
    });
  };

  // 新增停用試題 - 進行停用 打API
  // const [stateAddDisabledQuestion, sendAddDisabledQuestion] = useMachine(fetchDataMachine, {
  //   services: {
  //     fetchData: async (_context, event) => {
  //       const res = await postDisableQuestion(event.payload);
  //       const { isSuccess, systemCode, message } = res;
  //       if (isSuccess) {
  //         openNotificationWithIcon('success', '停用成功');
  //       }
  //       send(FETCH_DATA_EVENT.FETCH);
  //       disabledSend(FETCH_DATA_EVENT.FETCH);
  //       searchDataCancelHandler();
  //       return {
  //         isSuccess, systemCode, message
  //       };
  //     }
  //   }
  // });

  const doDisableQuestion = () => {
    sendDisableQuestion(FETCH_DATA_EVENT.FETCH, {
      payload: {
        education: searchData.education,
        subject: searchData.subject,
        itemID: searchData.uid,
        description: searchData.description,
        isDisabled: true,
      }
    });
  };

  const doSomeThing = () => {
    if (detailType === 'Anomaly') {
      //錯誤回報
      if (confirmText.isDisabled) {
        sendDisableQuestion(FETCH_DATA_EVENT.FETCH, {
          payload: {
            education: detailData.eduSubject.substring(0, 1),
            subject: detailData.eduSubject.substring(1, 3),
            itemID: detailData.questionUID,
            // description: '',
            isDisabled: true,
          }
        });
      } else {
        sendDisableQuestion(FETCH_DATA_EVENT.FETCH, {
          payload: {
            education: detailData.eduSubject.substring(0, 1),
            subject: detailData.eduSubject.substring(1, 3),
            itemID: detailData.questionUID,
            description: '',
            isDisabled: false,
          }
        });
      }
    } else {
      //已停用試題
      sendDisableQuestion(FETCH_DATA_EVENT.FETCH, {
        payload: {
          education: detailData.eduSubject.substring(0, 1),
          subject: detailData.eduSubject.substring(1, 3),
          itemID: detailData.questionUID,
          // description: '',
          isDisabled: false,
        }
      });
    }
  };

  // 將清單資料 重新整理
  const reBuildRecordGroup = (recordGroup) => {
    const newRecordGroup = recordGroup.map((item) => {
      const { records } = item;
      const firstRecord = records[0];
      const newItem = {
        ...item,
        eduName: item.education || '',
        subjectName: item.subject || '',
        lastUpdateTime: substringTime(item.lastUpdateTime) || substringTime(item.updateTime) || '',
        subject: item.subject || '',
        // createTime: substringTime(item.createTime) || '',
        // maintainer: item.maintainer || '',
        // anomalyType: item.anomalyType || '',
      };
      return newItem;
    });
    return newRecordGroup;
  };

  // 時間切割顯示
  const substringTime = (time) => {
    if (!time) return;
    return time.substring(0, 10);
  };

  // 停用者 字串切割 只剩名稱
  const substringName = (name) => {
    if (!name) return;
    return name.split(']')[1];
  };

  //問題類別配對顯示
  const importedDisabledType = (value) => {
    // let key;
    // disabledTypeOptions &&
    // disabledTypeOptions.forEach((item, index) => {
    //   if (item.value === value) {
    //     key = index;}
    // });
    // return disabledTypeOptions[key].name;
    return '';
  };

  // 篩選後顯示的 科目列表
  const importedSubjectOptions = useMemo(() => {
    if (!subjectOptions) return [];
    if (!filter.education || '') {
      return subjectOptions;
    } else {
      return subjectOptions.filter(item => item.eudCode === filter.education);
    }
  }, [filter.education, subjectOptions]);

  const importedDisabledSubjectOptions = useMemo(() => {
    if (!disabledSubjectOptions) return [];
    if (!anomalyFilter.education) {
      return disabledSubjectOptions;
    } else {
      return disabledSubjectOptions.filter(item => item.eudCode === anomalyFilter.education);
    }
  }, [anomalyFilter.education, disabledSubjectOptions]);

  // 錯誤回報 - 篩選 顯示的列表
  const filterRecordGroup = useMemo(() => {
    if (!recordGroup) return [];
    let nextData = [].concat(recordGroup);
    Object.entries(filter).forEach((key, value) => {
      if (key[1] !== '') {
        nextData = nextData.filter(item => item[key[0]] === key[1]
        );
      }
    });
    return nextData.sort(function(a, b) {
      return new Date(b.lastUpdateTime) - new Date(a.lastUpdateTime);
    });
  }, [filter, recordGroup]);

  // 篩選 停用試題 顯示的列表
  const filterDisabledRecordGroup = useMemo(() => {
    if (!disabledRecordGroup) return [];
    let nextData = [].concat(disabledRecordGroup);
    Object.entries(anomalyFilter).forEach((key, value) => {
      if (key[1] !== '') {
        nextData = nextData.filter(item => item[key[0]] === key[1]
        );
      }
    });
    return nextData.sort(function(a, b) {
      return new Date(b.updateTime) - new Date(a.updateTime);
    });
  }, [anomalyFilter, disabledRecordGroup]);
  // 進行篩選動作
  const onFilterChange = (value, type) => {
    if (type === 'education') {
      setFilter({
        [type]: value,
        subject: ''
      });
    } else {
      setFilter({
        ...filter,
        [type]: value,
      });
    }
  };

  const onAnomalyFilterChange = (value, type) => {
    if (type === 'education') {
      setAnomalyFilter({
        [type]: value,
        subject: ''
      });
    } else {
      setAnomalyFilter({
        ...anomalyFilter,
        [type]: value,
      });
    }
  };

  const onChangeTextArea = (e) => {
    setSearchData({
      ...searchData,
      description: e.target.value
    });
  };

  const onAddDisableFilterChange = (value, type) => {
    if (type === 'education') {
      setAddDisableSelectParams({
        [type]: value,
        subject: ''
      });
      setSearchData({
        ...searchData,
        education: value,
        subject: ''
      });
    } else {
      const { education } = addDisableSelectParams;
      setAddDisableSelectParams({
        education: education,
        [type]: value,
      });
      setSearchData({
        ...searchData,
        subject: value,
      });
    }
  };

  // 試題ID 搜尋
  const onSearchBarChange = (e) => {
    setSearchData({
      ...searchData,
      uid: e.target.value,
    });
    // if (e.target.value.length === 32) {
    //   doSearch();
    // }
  };

  useEffect(() => {
    if (searchData.uid.length === 32) {
      doSearch();
    }
  }, [searchData.uid]);

  const addDisableSubjectOptions = useMemo(() => {
    if (!addDisableSubjectMap || !addDisableSelectParams.education) return [];
    return setSelectOptions(addDisableSubjectMap[addDisableSelectParams.education]);
  }, [addDisableSelectParams.education]);


  // 確認目前 顯示 類型為何？ (問題回報 / 已停用停用試題)
  const [detailType, setDetailType] = useState('Anomaly');

  const tabChange = (key) => {
    setDetailType(key);
  };
  // 試題詳細 頁面 內容顯示
  const [detailData, setDetailData] = useState();

  // 確定 彈窗 顯示相關
  const [confirmText, setConfirmText] = useState({
    title: '',
    content: '',
    btnText: '',
    isDisabled: false,
  });

  const changeConfirmText = (boolean) => {
    if (boolean) {
      setConfirmText({
        title: '確定要停用此試題？',
        content: '停用後試題將不會顯示於前台搜尋結果。可於已停用試題列表重新啟用試題。確定停用請按「確定停用」',
        btnText: '確定停用',
        isDisabled: true,
      });
    } else {
      if (detailType === 'Anomaly') {
        setConfirmText({
          title: '確定不停用此試題？',
          content: '不停用，則試題將會維持顯示於前台搜尋結果。此筆回報將移除。確定不停用請按「確定不停用」',
          btnText: '確定不停用',
          isDisabled: false,
        });
      } else {
        setConfirmText({
          title: '確定要啟用此試題？',
          content: '啟用後試題將會顯示於前台搜尋結果。確定啟用請按「確定啟用」',
          btnText: '確定啟用',
          isDisabled: false,
        });
      }
    }
  };

  // 啟用按鈕
  const enableHandler = (data) => {
    setDetailData(data);
    showConfirmModal(false);
  };

  // 詳情頁面 顯示控制
  const [importDetailModalVisible, { toggle: setImportDetailModalToggle, setFalse: setImportDetailModalFalse }] = useBoolean(false);

  const showDisabledTest = (data) => {
    setImportDetailModalToggle();
    setDetailData(data);
  };

  // 確認提示視窗 顯示控制
  const [importConfirmModalVisible, { toggle: setImportConfirmVisibleModalToggle, setFalse: setImportConfirmVisibleModalFalse }] = useBoolean(false);

  const showConfirmModal = (boolean) => {
    setImportConfirmVisibleModalToggle();
    changeConfirmText(boolean);
  };

  // 新增 停用試題 視窗 顯示控制
  const [importAddDisableQuestionModalVisible, { toggle: setImportAddDisableQuestionModalToggle, setFalse: setImportAddDisableQuestionModalFalse }] = useBoolean(false);

  const showAddDisableQuestionModal = () => {
    setImportAddDisableQuestionModalToggle();
    // addDisabledOptionsStateSend(FETCH_DATA_EVENT.FETCH, {
    //   payload: {
    //   }
    // });
    sendGetAnomalyDisabledQueryEduSubject(FETCH_DATA_EVENT.FETCH);

  };

  // 翻頁相關功能
  const [anomalyPageState, setAnomalyPageState] = useState({
    currentPage: 1,
    limit: 10,
  });

  const anomalyPageChange = (currentPage, pageSize) => {
    setAnomalyPageState({
      ...anomalyPageState,
      currentPage,
      limit: pageSize,
    });
  };

  const [disabledPageState, setDisabledPageState] = useState({
    currentPage: 1,
    limit: 10,
  });

  const disabledPageChange = (currentPage, pageSize) => {
    setDisabledPageState({
      ...disabledPageState,
      currentPage,
      limit: pageSize,
    });
  };

  const [detailPageState, setDetailPageState] = useState({
    currentPage: 1,
    limit: 10,
  });

  const detailPageChange = (currentPage, pageSize) => {
    setDetailPageState({
      ...detailPageState,
      currentPage,
      limit: pageSize,
    });
  };

  useEffect(() => {
    send(FETCH_DATA_EVENT.FETCH);
    disabledSend(FETCH_DATA_EVENT.FETCH);
  }, []);

  return (
    <StyledQuestionDisablePage className={className} data-testid="QuestionDisablePage">
      <StyledHeader>
        <div className="title">停用試題</div>
        <Button className='addDisableQuestionBtn'
          onClick={() => {showAddDisableQuestionModal();}}><PlusOutline />停用試題</Button>
      </StyledHeader>
      <Tab defaultActiveKey="1" onChange={tabChange}>
        <TabPane tab="錯題回報" key="Anomaly">
          <div className='paneContent'>
            <StyledFilterBar>
              <PickListDropdown.SinglePickListDropdown
                defaultTitle="學制"
                options={educationOptions || []}
                onClick={(value) => onFilterChange(value, 'education')}
                value={filter.education}
                onPickListClear={() => {onFilterChange('', 'education');}}
              />
              {
                filter.education &&
                filter.education.length > 0 &&
                <PickListDropdown.SinglePickListDropdown
                  defaultTitle="科目"
                  options={importedSubjectOptions || []}
                  onClick={(value) => onFilterChange(value, 'subject')}
                  value={filter.subject}
                  onPickListClear={() => {onFilterChange('', 'subject');}}
                />
              }
            </StyledFilterBar>
            <Table>
              <Header>
                <Row className="tableTitle">
                  <Item flex='0.5'>學制</Item>
                  <Item>科目</Item>
                  <Item flex={6}>試題ID</Item>
                  <Item className='amount'flex='0.6'>回報比數</Item>
                  <Item flex={0.9}>最新回報時間</Item>
                  <Item flex='0.8'>詳細內容</Item>
                </Row>
              </Header>
              <Body>
                {
                  filterRecordGroup &&
                  filterRecordGroup.length > 0 ? sliceTableData(filterRecordGroup, anomalyPageState.currentPage, anomalyPageState.limit).map((item, index) => {
                      return (
                        <Row key={index}>
                          <Item flex='0.5'>{item.eduName}</Item>
                          <Item >{item.subjectName}</Item>
                          <Item flex="6">{item.questionUID}</Item>
                          <Item flex='0.6' className='amount'>{item.amount}</Item>
                          <Item flex="0.9">{item.lastUpdateTime}</Item>
                          <Item flex='0.8'>
                            <Button className='detailButton'
                              variant="brand2"
                              onClick={() => { showDisabledTest( item );}}>
                                詳細內容
                            </Button>
                          </Item>

                        </Row>
                      );
                    }) : <div className="emptyMsg">沒有待處理的回報</div>
                }
              </Body>
            </Table>
            <div className="pageBlock">
              <Pagination
                total={filterRecordGroup?.length || 0}
                defaultCurrent={1}
                current={anomalyPageState.currentPage}
                onChange={anomalyPageChange}
                defaultPageSize={anomalyPageState.limit}
              />
            </div>
          </div>
        </TabPane>
        <TabPane tab="已停用試題" key="Disable">
          <div className='paneContent'>
            <StyledFilterBar>
              <PickListDropdown.SinglePickListDropdown
                defaultTitle="學制"
                options={disabledEducationOptions || []}
                onClick={(value) => onAnomalyFilterChange(value, 'education')}
                value={anomalyFilter.education}
              />
              {
                anomalyFilter.education &&
                anomalyFilter.education.length > 0 &&
                <PickListDropdown.SinglePickListDropdown
                  defaultTitle="科目"
                  options={importedDisabledSubjectOptions || []}
                  onClick={(value) => onAnomalyFilterChange(value, 'subject')}
                  value={anomalyFilter.subject}
                />
              }
            </StyledFilterBar>
            <Table>
              <Header>
                <Row className="tableTitle">
                  <Item flex={0.45}>學制</Item>
                  <Item flex={1.2}>科目</Item>
                  <Item flex={4.3}>試題ID</Item>
                  <Item flex={1.2}>停用時間</Item>
                  <Item>停用者</Item>
                  <Item flex={1.2}>原因</Item>
                  <Item flex={1}>詳細內容</Item>
                  <Item flex={0.7}>操作</Item>
                </Row>
              </Header>
              <Body>
                {
                  filterDisabledRecordGroup &&
                  filterDisabledRecordGroup.length > 0 ? sliceTableData(filterDisabledRecordGroup, disabledPageState.currentPage, disabledPageState.limit).map((item, index) => {
                      return (
                        <Row key={index}>
                          <Item flex='0.45'>{item.eduName}</Item>
                          <Item flex={1.2}>{item.subjectName}</Item>
                          <Item flex="4.3">{item.questionUID}</Item>
                          <Item flex='1.2'>{item.lastUpdateTime}</Item>
                          <Item flex=''>{item?.records[0]?.userInfo?.userName}</Item>
                          <Item flex='1.2'>{item?.records[0]?.errorType}</Item>
                          <Item flex='1'>
                            <Button className='detailButton' variant="brand2" onClick={() => { showDisabledTest( item );}}>詳細內容</Button>
                          </Item>
                          <Item flex="0.7">
                            <Button className='detailButton' variant="brand1" onClick={ () => {enableHandler(item);}}>啟用</Button>
                          </Item>
                        </Row>
                      );
                    }) : <div className="emptyMsg">尚無停用的試題</div>
                }
              </Body>
              <div className="pageBlock">
                <Pagination
                  total={filterDisabledRecordGroup?.length || 0}
                  defaultCurrent={1}
                  current={disabledPageState.currentPage}
                  onChange={disabledPageChange}
                  defaultPageSize={disabledPageState.limit}
                />
              </div>
            </Table>
          </div>
        </TabPane>
      </Tab>

      {/* ----- 試題詳細頁面 ----- */}
      {
        detailData &&
        <Modal
          visible={importDetailModalVisible}
          onCancel={setImportDetailModalFalse}
          getContainer={document.getElementById('HomePage')}
          width="90%"
          title={detailType === 'Anomaly' ? '錯誤回報詳情' : '已停用試題詳情'}
          zIndex={900}
        >
          <StyledControlBtn>
            {
              detailType &&
              detailType === 'Anomaly' ?
                <div className='disableBox'>
                  <Button className='controlButton' variant="brand1" onClick={ () => {showConfirmModal(true); }}>停用</Button>
                  <Button className='controlButton dontDisableBtn' variant="brand1" onClick={ () => {showConfirmModal(false);}}>不停用</Button>
                </div> :
                <div className='enableBox'>
                  <Button className='controlButton' variant="brand1" onClick={ () => {showConfirmModal(false);}}>啟用</Button>
                </div>
            }
          </StyledControlBtn>

          <StyledDetailHeaderBox>
            <div>
              <div>學制</div>
              <div>{detailData?.education}</div>
            </div>
            <div>
              <div>科目</div><div>{detailData?.subject}</div>
            </div>
            <div>
              <div>試題ID</div><div>{detailData?.questionUID}</div>
            </div>
          </StyledDetailHeaderBox>

          <StyledDetailImageBox>
            <div>
              <img src={detailData?.view} alt={detailData?.questionUID}></img>
            </div>
          </StyledDetailImageBox>

          <StyledCountBox>
            <div>
              回報列表
            </div>
            <div>
              共 {detailData?.amount} 筆
            </div>
          </StyledCountBox>

          <StyledDetailTableBox>
            <Table>
              <Header>
                <Row className="tableTitle">
                  <Item>回報時間</Item>
                  <Item>原因</Item>
                  <Item flex={4}>說明</Item>
                  <Item>回報者</Item>
                  <Item flex={1.2}>回報服務</Item>
                </Row>
              </Header>
              <Body>
                {
                  detailData.records &&
                  detailData.records.length > 0 ? sliceTableData(detailData.records, detailPageState.currentPage, detailPageState.limit).map((item, index) => {
                      return (
                        <Row key={index}>
                          <Item>{substringTime(item?.createTime || '')}</Item>
                          <Item>{item?.errorType || ''}</Item>
                          <Item flex={4} className="descriptionBox">{item?.description || ''}</Item>
                          <Item>{item?.userInfo?.userName || ''}</Item>
                          <Item flex={1.2}>{item?.userInfo?.comeFrom || ''}</Item>
                        </Row>
                      );
                    }) : ''
                }
              </Body>
              <div className="pageBlock">
                <Pagination
                  total={detailData.records?.length || 0}
                  defaultCurrent={1}
                  current={detailPageState.currentPage}
                  onChange={detailPageChange}
                  defaultPageSize={detailPageState.limit}
                />
              </div>
            </Table>
          </StyledDetailTableBox>
        </Modal>
      }
      {/* ----- 確認 彈窗 ----- */}
      <Modal
        visible={importConfirmModalVisible}
        onCancel={setImportConfirmVisibleModalFalse}
        getContainer={document.getElementById('HomePage')}
        width="40%"
        title={confirmText?.title}
        footer={
          <>
            <Button className='button' variant='ui01' onClick={() => {setImportConfirmVisibleModalFalse();}}>取消</Button>
            <Button className='button' variant="brand2" onClick={() => {doSomeThing();}}>{confirmText.btnText}</Button>
          </>
        }
      >
        <StyledConfirmContent>
          {confirmText.content}
        </StyledConfirmContent>
      </Modal>

      {/* ----- 新增 停用試題 彈窗 ----- */}
      <Modal
        visible={importAddDisableQuestionModalVisible}
        zIndex={1001}
        onCancel={searchDataCancelHandler}
        title={'停用試題'}
        width="576px"
        footer={
          <>
            <Button variant='ui01' onClick={searchDataCancelHandler}>取消</Button>
            <Button variant={(!comparisonResult || searchData.uid.length !== 32) ? 'ui02' : 'brand2'}
              disabled={!comparisonResult || searchData.uid.length !== 32}
              onClick={doDisableQuestion}>
                確定停用
            </Button>
          </>
        }
      >
        <StyledAddDisableContent>
          <div>
            <div>學制科目</div>
            <div>
              <StyledFilterBar>
                <PickListDropdown.SinglePickListDropdown
                  defaultTitle="學制"
                  options={addDisabledEducationOptions || []}
                  onClick={(value) => onAddDisableFilterChange(value, 'education')}
                  value={addDisableSelectParams.education}
                />
                {
                  addDisableSelectParams.education &&
                  addDisableSelectParams.education.length > 0 &&
                  <PickListDropdown.SinglePickListDropdown
                    defaultTitle="科目"
                    options={addDisableSubjectOptions || []}
                    onClick={(value) => onAddDisableFilterChange(value, 'subject')}
                    value={addDisableSelectParams.subject}
                  />
                }
              </StyledFilterBar>
            </div>
          </div>
          <div>
            <div>試題ID</div>
            <div className={classNames('searchBar', checkTextIsShow && !comparisonResult && 'checkWrongText' )}>
              <Input placeholder={!searchData.subject.length > 0 ? '請先選擇學制科目' : '請輸入試題ID'}
                allowClear={false}
                maxLength={32}
                onChange={onSearchBarChange}
                disabled={!searchData.subject.length > 0}
                isLoading={searchIsLoading}>
              </Input>
            </div>
          </div>
          {/* 訊息提示 */}
          {
            checkTextIsShow &&
            <div>
              <div></div>
              <div className={comparisonResult ? 'checkRightText' : 'checkWrongText'}>
                {comparisonResult ? <span><CheckCircleSolid/>試題ID輸入無誤 </span> : <span><ExclamationCircleSolid/> 試題ID輸入有誤</span>}
              </div>
            </div>
          }
          <div>
            <div>說明</div>
            <div className='textArea'>
              <Input.TextArea autoSize={true} placeholder="請輸入停用試題的說明原因"
                onChange={onChangeTextArea} disabled={!comparisonResult || searchData.uid.length !== 32}>
              </Input.TextArea>
            </div>
          </div>
          {
            searchData.image &&
            <div className='imageSection'>
              <div>試題圖片檢視</div>
              <div className='imageBox'>
                <img src={searchData?.image} alt={searchData?.uid}></img>
              </div>
            </div>
          }
        </StyledAddDisableContent>
      </Modal>
    </StyledQuestionDisablePage>
  );
};