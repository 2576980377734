import React, { useState, useEffect, useRef } from 'react';
import Hammer from 'hammerjs';
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  isSameMonth,
  isSameDay,
  isSameYear,
  addDays,
  addMonths,
  subMonths,
  getMonth,
  getYear,
  addYears,
  subYears,
  setYear,
  getWeeksInMonth,
  addWeeks,
  subWeeks,
  getUnixTime
} from 'date-fns';
import { ChevronDownOutline, ChevronLeftOutline, ChevronRightOutline, CalenderTodaySolid } from '@onedesign/icon';
import { useWindowSize } from '@/hooks/useResize';
import {
  StyledDatePicker,
  UiPanelHeader,
  UiLeftTool,
  UiModeBtn,
  UiDateSelectBtn,
  UiPicker,
  UiPickerHead,
  UiPickerController,
  UiPickerBody,
  UiDayPicker,
  UiMonthPicker,
  UiYearPicker,
  SelectPicker,
  UiMobileTool,
  CollapseBtn
} from './DatePicker.style';

export type DatePickerProps = {
    className?: string,
    activeDate?: number,
    onChange?: (date: number)=> void

};

export type DayPanelRenderProps = {
  currentDate: Date,
  selectedDate: Date,
  onSetCurrentDate: (date: Date)=> void,
  onDateClick: (date: Date)=> void,
  generateDateItem: (date: Date)=> React.ReactNode
};

export type WeekPanelRenderProps = {
  currentDate: Date,
  selectedDate: Date,
  onDateClick: (date: Date)=> void,
};

const monthArr = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];

const todayDate = new Date();

export const DayPanelRender = (props: DayPanelRenderProps) => {
  const { currentDate, generateDateItem } = props;
  const monthStart = startOfMonth(currentDate);
  return <>{generateDateItem(monthStart)}</>;
};

export const WeekPanelRender = (props: WeekPanelRenderProps) => {
  const { currentDate, selectedDate, onDateClick } = props;
  const monthStart = startOfMonth(currentDate);
  const startDate = startOfWeek(currentDate, { weekStartsOn: 1 });
  const dateFormat = 'd';
  const days = [];
  let day = startDate;
  let dayFormattedDate = '';
  let fullFormattedDate = '';

  for (let i = 0; i < 7; i++) {
    dayFormattedDate = format(day, dateFormat);
    fullFormattedDate = format(day, 'yyyy-MM-dd');
    const cloneDay = day;
    days.push(
      <div
        className={`column cell${isSameDay(day, todayDate) ? ' today' : ''}${!isSameMonth(day, monthStart)
          ? ' disabled' : isSameDay(day, selectedDate)
            ? ' selected' : '' }`}
        onClick={() => onDateClick(new Date(cloneDay))}
        key={fullFormattedDate}
      >
        <span className="number">{dayFormattedDate}</span>
      </div>
    );
    day = addDays(day, 1);
  }

  return <UiPickerBody>
    <div className="row"> {days} </div>
  </UiPickerBody>;
};

export const DatePicker = (props: DatePickerProps) => {
  const { activeDate, onChange, className } = props;
  const formateActiveDate = activeDate && new Date(activeDate);
  const [currentDate, setCurrentDate] = useState(formateActiveDate || new Date());
  const [selectedDate, setSelectedDate] = useState(formateActiveDate || new Date());
  const [currentMode, setCurrentMode] = useState('day');
  const [modeBtnText, setModeBtnText] = useState('');
  const [mobileSelectValue, setMobileSelectValue] = useState<Array<string>>([]);
  const dayPanelElm = useRef<HTMLDivElement>(null);
  const weekPanelElm = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();

  const generateDateItem = (date: Date) => {
    const monthStart = startOfMonth(date);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
    let endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });
    const dateFormat = 'd';
    const weeksInMonth = getWeeksInMonth(monthEnd, { weekStartsOn: 1 });
    const baseWeeks = 6;
    const rows = [];
    let days = [];
    let day = startDate;
    let dayFormattedDate = '';
    let fullFormattedDate = '';

    if (weeksInMonth < baseWeeks) {
      endDate = addWeeks(endDate, baseWeeks - weeksInMonth);
    }

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        dayFormattedDate = format(day, dateFormat);
        fullFormattedDate = format(day, 'yyyy-MM-dd');
        const cloneDay = day;
        days.push(
          <div data-testid={`DateCell-${fullFormattedDate}`}
            className={`column cell${isSameDay(day, todayDate) ? ' today' : ''}${!isSameMonth(day, monthStart)
              ? ' disabled' : isSameDay(day, selectedDate)
                ? ' selected' : '' }`}
            key={fullFormattedDate}
            onClick={() => onDateClick(new Date(cloneDay))}
          >
            <span className="number">{dayFormattedDate}</span>
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="row" key={fullFormattedDate}> {days} </div>
      );
      days = [];
    }
    return <UiPickerBody>{rows}</UiPickerBody>;
  };

  const generatePickerSelectData = () => {
    const century = 100 * 2;
    const baseYear = 1912;
    const yearData = [];

    const monthData = monthArr.map((month, index) => {
      let value = '';

      if (index < 9) {
        value = (`0${index + 1}`).toString();
      } else {
        value = (index + 1).toString();
      }
      return {
        label: month.toString(),
        value
      };
    });
    for (let index = 0; index < century; index++) {
      yearData.push({
        label: (baseYear + index).toString(),
        value: (baseYear + index).toString()
      });
    }
    return [yearData, monthData];
  };

  const pickerSelectHandler = (value: Array<[]>) => {
    const year = value[0].toString();
    let month = value[1].toString();

    if (month.length === 1) {
      month = `0${month}`;
    }
    const selectVal = new Date(`${year}-${month}`);
    setCurrentDate(selectVal);
  };

  // ============================== Header ==============================
  const modeBtnTextRender = () => {
    let text = '';
    let dateFormat = '';
    const rangeYear = getYear(currentDate);
    const baseYear = rangeYear - (rangeYear % 10);

    switch (currentMode) {
      case 'month':
        dateFormat = 'yyyy年';
        break;
      case 'year':
        dateFormat = `${baseYear}-${baseYear + 11}年`;
        break;
      default:
        dateFormat = 'yyyy年M月';
        break;
    }
    text = format(currentDate, dateFormat);
    setModeBtnText(text);
  };

  const modeChangeHandler = () => {
    let mode = '';
    switch (currentMode) {
      case 'month':
        mode = 'year';
        break;
      case 'year':
        mode = 'year';
        break;
      default:
        mode = 'month';
        break;
    }
    setCurrentMode(mode);
  };

  const mobileModeChangeHandler = () => {
    const mode = currentMode === 'week' ? 'day' : 'week';
    setCurrentMode(mode);
  };

  const nextHandler = () => {
    switch (currentMode) {
      case 'month':
        setCurrentDate(addYears(currentDate, 1));
        break;
      case 'year':
        setCurrentDate(addYears(currentDate, 10));
        break;
      default:
        setCurrentDate(addMonths(currentDate, 1));
        break;
    }
  };
  const prevHandler = () => {
    switch (currentMode) {
      case 'month':
        setCurrentDate(subYears(currentDate, 1));
        break;
      case 'year':
        setCurrentDate(subYears(currentDate, 10));
        break;
      default:
        setCurrentDate(subMonths(currentDate, 1));
        break;
    }
  };

  const backTodayHandler = () => {
    const unixTime = getUnixTime(todayDate) * 1000;

    setCurrentDate(new Date());
    setSelectedDate(new Date());
    setCurrentMode('day');

    onChange && onChange(unixTime);
  };

  const header = () => {
    return (
      <UiPanelHeader>
        <UiLeftTool>
          <UiModeBtn data-testid="DateModeBtn" onClick={modeChangeHandler}>
            {modeBtnText}
          </UiModeBtn>
          <UiMobileTool>
            <SelectPicker
              data={generatePickerSelectData()}
              title="選擇日期"
              cascade={false}
              value={mobileSelectValue}
              okText='確定'
              dismissText='取消'
              onOk={value => pickerSelectHandler(value)}
            >
              <UiDateSelectBtn>
                {format(currentDate, 'M')}月
              </UiDateSelectBtn>
            </SelectPicker>
            <CollapseBtn className={`${currentMode === 'day' ? '' : 'dayMode'}`} onClick={mobileModeChangeHandler}>
              <ChevronDownOutline />
            </CollapseBtn>
          </UiMobileTool>
          <UiPickerController>
            <div className="item" onClick={prevHandler}>
              <ChevronLeftOutline />
            </div>
            <div className="item" onClick={nextHandler}>
              <ChevronRightOutline />
            </div>
          </UiPickerController>
        </UiLeftTool>
        {currentMode !== 'week' && <div className='todayBtn' onClick={backTodayHandler}>
          <CalenderTodaySolid />
        </div>}
      </UiPanelHeader>
    );
  };

  // =============================== pickerHead ===============================
  const daysOfWeek = () => {
    const days = [];
    const weekDaysLocale: string[] = ['一', '二', '三', '四', '五', '六', '日'];
    const WEEK_DAY_COUNT = 7;
    for (let i = 0; i < WEEK_DAY_COUNT; i += 1) {
      days.push(
        <div className="column" key={i}>
          {weekDaysLocale[i]}
        </div>
      );
    }

    return <UiPickerHead>
      <div className="days row">{days}</div>
    </UiPickerHead>;
  };

  // =============================== pickerBody ===============================
  const onDateClick = (day: Date) => {
    const unixTime = getUnixTime(day) * 1000;
    setSelectedDate(day);
    setCurrentDate(day);

    onChange && onChange(unixTime);
  };

  const monthClickHandler = (date: string) => {
    setCurrentDate(new Date(date));
    setCurrentMode('day');
  };

  const yearClickHandler = (year: number) => {
    const date = setYear(new Date(currentDate), year);
    setCurrentDate(date);
    setCurrentMode('month');
  };

  const monthPanelRender = () => {
    const months = [];
    const currentYear = getYear(currentDate);
    const todayMonth = getMonth(todayDate);

    for (let index = 0; index < monthArr.length; index++) {
      months.push(
        <div
          data-testid={`MonthCell-${index}`}
          key={`${currentYear}-${index}`}
          className={`monthCell ${isSameYear(currentDate, todayDate) && todayMonth === index ? 'current' : null }`}
          onClick={() => monthClickHandler(`${currentYear}-${index + 1}-1`)}>
          <div className="number">
            {monthArr[index]}
          </div>
        </div>
      );
    }
    return <UiMonthPicker data-testid='MonthPicker'>{months}</UiMonthPicker>;
  };

  const yearPanelRender = () => {
    const years = [];
    const currentYear = getYear(currentDate);
    const baseYear = currentYear - (currentYear % 10);
    const todayYear = getYear(todayDate);

    for (let index = 0; index < 12; index++) {
      years.push(
        <div
          data-testid={`YearCell-${index}`}
          key={baseYear + index}
          className={`yearCell ${(baseYear + index === todayYear) ? 'current' : null}`}
          onClick={() => yearClickHandler(baseYear + index)}>
          <div className="number">
            {baseYear + index}
          </div>
        </div>
      );
    }
    return <UiYearPicker data-testid='YearPicker'>{years}</UiYearPicker>;
  };

  useEffect(() => {
    modeBtnTextRender();
  }, [currentMode, currentDate]);

  useEffect(() => {
    const month = format(currentDate, 'MM');
    const year = format(currentDate, 'yyyy');
    setMobileSelectValue([year, month]);
  }, [currentDate]);

  useEffect(() => {
    let dayPanelHammer = null;
    let weekPanelHammer = null;


    switch (currentMode) {
      case 'day':
        if (!dayPanelElm.current) return;
        dayPanelHammer = new Hammer(dayPanelElm.current);
        dayPanelHammer.on('swipeleft', () => setCurrentDate(prevDate => addMonths(prevDate, 1)));
        dayPanelHammer.on('swiperight', () => setCurrentDate(prevDate => subMonths(prevDate, 1)));
        break;
      case 'week':
        if (!weekPanelElm.current) return;
        weekPanelHammer = new Hammer(weekPanelElm.current);
        weekPanelHammer.on('swipeleft', () => setCurrentDate(prevDate => addWeeks(prevDate, 1)));
        weekPanelHammer.on('swiperight', () => setCurrentDate(prevDate => subWeeks(prevDate, 1)));
        break;
      default:
        break;
    }
  }, [currentMode]);

  useEffect(() => {
    if (!windowSize.width) return;
    if (windowSize.width < 768) {
      setCurrentMode('week');
    } else {
      setCurrentMode('day');
    }
  }, [windowSize.width]);

  useEffect(() => {
    setCurrentDate(formateActiveDate || new Date());
    setSelectedDate(formateActiveDate || new Date());
  }, [activeDate]);

  return (
    <StyledDatePicker data-testid="DatePicker" className={className}>
      {header()}
      <UiPicker>
        {currentMode === 'day' &&
          <UiDayPicker data-testid='DayPicker' ref={dayPanelElm}>
            {daysOfWeek()}
            <DayPanelRender
              onSetCurrentDate={setCurrentDate}
              generateDateItem={generateDateItem}
              currentDate={currentDate}
              selectedDate={selectedDate}
              onDateClick={onDateClick} />
          </UiDayPicker>}
        {currentMode === 'week' &&
          <UiDayPicker data-testid='WeekPicker' ref={weekPanelElm}>
            {daysOfWeek()}
            <WeekPanelRender currentDate={currentDate} selectedDate={selectedDate} onDateClick={onDateClick} />
          </UiDayPicker>}
        {currentMode === 'month' && monthPanelRender()}
        {currentMode === 'year' && yearPanelRender()}
      </UiPicker>
    </StyledDatePicker>
  );
};

