import styled from 'styled-components';
import { space } from 'styled-system';

export const StyledQuestionDisablePage = styled.div`

    .ant-tabs-tab{
        padding: 12px 20px;
        &:hover {
            color: ${({ theme }) => theme.colors.brand.brand01};
        }
        &+ .ant-tabs-tab{
            margin:0 0 0 0;
        }
        &.ant-tabs-tab-active{
            .ant-tabs-tab-btn {
                color: ${({ theme }) => theme.colors.brand.brand01};
            }
        }
    }
    .ant-tabs-ink-bar{
        background: ${({ theme }) => theme.colors.brand.brand01};
    }
    .tableTitle{
        height: 48px;
    }
    .emptyMsg{
		text-align: center;
		margin-top: 80px;
		color: ${({ theme }) => theme.colors.text.text_06};
	}
    .detailButton{
        font-size: 11px !important;
        padding: 8px 12px !important;
        /* width: 75% !important; */
    }
    .amount{
        justify-content: flex-end;
    }
    .addDisableQuestionBtn{
        border: 1px solid ${({ theme }) => theme.colors.ui.ui_04};
    }
    .searchBar{

    }
    .pageBlock {
		margin-top: 25px;
	}
`;

export const StyledFlexBox = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;

	&:first-child {
		border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_04};
	}

    .label{
        margin-bottom: 16px;
		font-size: 18px;
		font-weight: normal;
        &>div{
            display: flex;
            margin-left: 8px;
        }
    }
    ${space}
`;

export const StyledControlBtn = styled.div`
	position: absolute;
    top: 32px;
    right: 128px;
    .disableBox{
        display: flex;
        justify-content: space-around;
        align-items: center;
        div{
            margin: 0 8px;
        }
        .dontDisableBtn{
            background-color: #fff !important;
            color: ${({ theme }) => theme.colors.brand.brand01} !important;
            border: 1px #D5D7DE solid !important;
        }
    }

    .controlButton{
        padding: 8px 16px !important;
    }
`;

export const StyledDetailHeaderBox = styled.div`
	>div{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 8px 0;

        >div:first-child{
		    color: ${({ theme }) => theme.colors.text.text_06};
            width: 96px;
        }
    }
`;

export const StyledDetailImageBox = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.ui.ui_04};
	border-radius:8px;
    margin: 40px 0px;
    >div{
        display: flex;
        justify-content: center;
        align-items: center;
        >img{
            width: 560px;
        }
    }
`;

export const StyledCountBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    >div:first-child{
        font-family: Noto Sans CJK TC;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
    }
    >div:nth-child(2){
		color: ${({ theme }) => theme.colors.text.text_06};
    }
`;

export const StyledDetailTableBox = styled.div`
    .descriptionBox{
        word-break: break-word;
    }
`;

export const StyledConfirmContent = styled.div`
	color: ${({ theme }) => theme.colors.text.text_06};
`;

export const StyledAddDisableContent = styled.div`
    >div{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0 0 16px 0;
        >div:first-child{
            width: 80px;
        }
    }
    >div:first-child{
        >div{
            >div{
                >div{
                    margin-bottom: 0px !important;
                    >div:first-child{
                        margin-right: 16px !important;
                    }
                }
            }
        }
    }
    .imageSection{
        margin-top: 24px;
        display: block;
        border-top: 1px solid ${({ theme }) => theme.colors.ui.ui_04};
        padding-top: 28px;
        >div{
            width: 100% !important;
            >img{
                
            }
        }
    }
    .searchBar{
        width: 80%;
    }
    .searchBar.checkWrongText{
        border: 1px solid #F44336
    }
    >div:nth-child(3){
        align-items: flex-start;
    }
    .textArea{
        width: 80%;
    }
    .imageBox{
        margin-top: 21px;
        border: 1px solid ${({ theme }) => theme.colors.ui.ui_04};
	    border-radius:8px;
        overflow: hidden;
        >img{
            width: 100%;
        }
    }
    >div:nth-child(3){
        >div>span{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            >svg{
                margin-right: 5px;
            }
        }
    }
    .checkRightText{
        color: #4CAF50;
    }
    .checkWrongText{
        color: #F44336
    }
`;