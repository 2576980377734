import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider, ThemeProvider } from '@oneclass/ui-components';
import { ConfigProvider } from 'antd';
import zhTW from 'antd/lib/locale/zh_TW';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import 'antd/dist/antd.css';
import './index.css';


const root = document.getElementById('root');
const Main = () => (
  <BrowserRouter>
    <AuthProvider.Provider apiBaseUrl={process.env.REACT_APP_CMS_DOMAIN} env={process.env.REACT_APP_NODE_ENV}>
      <ThemeProvider>
        <DndProvider backend={HTML5Backend}>
          <ConfigProvider locale={zhTW}>
            <App />
          </ConfigProvider>
        </DndProvider>
      </ThemeProvider>
    </AuthProvider.Provider>
  </BrowserRouter>
);

ReactDOM.render(Main(), root);

reportWebVitals();
