import styled from 'styled-components';
import { space } from 'styled-system';
export const StyledHomePage = styled.div`

	.count{
		margin:20px 0;
	}
	.clickable{
		cursor: pointer;
		font-size: 24px;
	}
	.pageBlock {
		margin-top: 25px;
	}
	
	.emptyMsg{
		text-align: center;
		margin-top: 80px;
		color: ${({ theme }) => theme.colors.text.text_06};
	}
	.choiceBar{
		display: flex;
		align-items: center;
		& > div{
			margin-left:16px;
		}
	}
	.useTypeArea{
		display: flex;
		align-items: center;
		&> div{
			margin-left: 16px;
		}
	}
	.tableTitle{
		height:48px;
		white-space: nowrap;
	}
	.button{
		padding: 8px 30px !important;
	}
	.tableBody{
		overflow: visible;
	}
	.refreshBtn{
		padding: 8px 12px !important;
	}
	.isRefresh{
		opacity: 0.4;
		>.refreshIcon{
			font-size: 17px;
			animation-name: spin ;
			animation-duration:1s;
			animation-iteration-count:infinite;
			animation-timing-function:linear;
		}
		@keyframes spin{
			0%{
				transform: rotate(0deg)
			}
			100%{
				transform: rotate(360deg)
			}
		}
	}
	.toolTipIcon{
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: bold;
		color: #fff;
		width: 15px;
		height: 15px;
		cursor: pointer;
		border-radius: 50%;
		background-color: #8B90A0;
		margin-left: 10px;
	}
	.waitingRefresh{
		color: #A1A4B1;
		background-color: #F2F4F8;
		border-radius: 4px;
		cursor: pointer;
	}
	.boxShadowLeft{
		box-shadow: -2px 0px 8px rgba(0, 0, 0, 0.1);
	}
`;

export const StyledHomeHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	
	.title{
		font-size: 24px;
	}
`;

export const StyledHomePageContent = styled.div`
    position: relative;
	display: flex;
	overflow: auto;
`;

export const StyledFilterBar = styled.div`
    position: relative;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	> *{
		margin-bottom: 8px;
		&:not(:last-child){
			margin-right: 8px;
		}
	}
`;

export const StyledFlexBox = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;

	&:first-child {
		border-bottom: 1px solid ${({ theme }) => theme.colors.ui.ui_04};
	}

    .label{
        margin-bottom: 16px;
		font-size: 18px;
		font-weight: normal;
        &>div{
            display: flex;
            margin-left: 8px;
        }
    }
    ${space}
`;

export const StyledSelectBtn = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin: 8px 0;
	margin-right: 8px;
	padding: 8px 30px;
	color: ${({ active, theme }) => (active ? theme.colors.text.white : theme.colors.brand.brand02)};
	background: ${({ active, theme }) => (active ? theme.colors.brand.brand02 : theme.colors.ui.white)};
	border: 1px solid ${({ theme }) => theme.colors.ui.ui_04};
	border-radius: 4px;
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
	flex-direction: column;
	box-sizing: border-box;
	flex: none;
	order: 0;
	cursor: pointer;
`;