import Api from '../index';
// import { checkNaniLinkLogin } from "services/oneClub";
/**
 * 取得登錄資料
 */
export const loginByOneClub = async ({ jwt, url }) => {//cms Login
  const response = await Api.get(`${url}/api/Login`, {}, { headers: { Authorization: jwt || null } });
  return response;
};

// export const loginFrontEndByOneClub = async ({ jwt, url }) => {
//   const response = await Api.get(`${url}/api/Member/Login`, {}, { headers: { Authorization: jwt || null } });
//   return response;
// };

// 新的 前台 登入ＡＰＩ 取得 使用者身份
export const loginFrontEndByOneClub = async ({ jwt, url, serviceRoot, organizationId }) => {
  if (organizationId) {
    const response = await Api.post(`${url}/api/Member/PostLogin?serviceRoot=${serviceRoot}`, { jwtToken: jwt || null, organizationId });
    return response;
  } else {
    const response = await Api.post(`${url}/api/Member/PostLogin?serviceRoot=${serviceRoot}`, { jwtToken: jwt || null });
    return response;
  }
};