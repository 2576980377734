import { Api } from '@oneclass/ui-components';

// 取得 內部員工列表
export const getMemberList = async () => {
  const response = await Api.get(`${process.env.REACT_APP_CMS_DOMAIN}/api/Member/Editors`);
  return response;
};

// 啟用/停用 後台管理權限
export const putCmsAuthorityControl = async (payload) => {
  const response = await Api.put(`${process.env.REACT_APP_CMS_DOMAIN}/api/Member/Editors/${payload.UID}?isActive=${payload.isActive}`, payload);
  return response;
};

// 編輯權限 & 標籤設定
export const putAccountEdit = async (payload) => {
  const response = await Api.put(`${process.env.REACT_APP_CMS_DOMAIN}/api/Member/Editors`, payload);
  return response;
};

// 取得 所有標籤
export const getTagsList = async () => {
  const response = await Api.get(`${process.env.REACT_APP_CMS_DOMAIN}/api/Tag/`);
  return response;
};

// 新API

export const getMemberInformation = async () => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Member/Information`);
  return response;
};

// 取得與用戶相關的「動態參數」與「靜態參數」
export const getMemberParameter = async () => {
  const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Member/Parameter`);
  return response;
};