import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useBoolean, useClickAway } from 'ahooks';
import { SidebarLayout, Dropdown, AuthProvider, checkAuthorityIsOpen } from '@oneclass/ui-components';
import RenderRoutes from 'routes/RenderRoutes';
import { ArrowAltCircleLeftSolid, HomeSolid, UserSolid, DiceD6Solid, UserCogSolid, SignOutSolid, ReturnSolid, ListAltSolid, ChevronDownSolid, CogSolid, KeySolid, TagsSolid, ScrollSolid } from '@onedesign/icon';
import { Link, useLocation } from 'react-router-dom';
import { StyledMainLayout, StyledHeader, UiUserBox, StyledLink, StyledLinkGroup } from './MainLayout.style';

export const MainLayout = ({ className, routes = [] }) => {
  const env = process.env.REACT_APP_NODE_ENV;
  const { useAuthDispatch, useAuthState } = AuthProvider;
  const {
    logout
  } = useAuthDispatch();
  const authState = useAuthState();
  const authorityArray = authState?.context?.userDetails?.authority;
  const location = useLocation();
  const [state, { toggle, setFalse }] = useBoolean(false);
  const drawRef = useRef();
  useClickAway(() => {
    setFalse();
  }, drawRef);

  // 側邊選單 權限管理 展開控制
  const [authorityManagementIsOpenState, { toggle: authorityManagementIsOpenToggle, setFalse: setAuthorityManagementIsOpenFalse }] = useBoolean(false);

  useEffect(() => {
    if (location.pathname.split('/')[1] === 'authorityManagement') authorityManagementIsOpenToggle();
  }, []);

  return (
    <StyledMainLayout className={className} data-testid="MainLayout">
      <SidebarLayout
        contentWidth={true}
        sidebar={
          <div>
            <div className="sidebarTitle">後台管理</div>
            <div className="sidebarList">

              <StyledLink>
                <a href={`https://onepaper${env !== 'release' ? `-${env}` : ''}.oneclass.com.tw/`}><ArrowAltCircleLeftSolid/>返回前台</a>
              </StyledLink>
              {/* <StyledLink active={location.pathname === '/ruleSearch'}>
                <Link to="/ruleSearch"><DiceD6Solid/>前台設定</Link>
              </StyledLink> */}
              {
                checkAuthorityIsOpen('規格管理', authorityArray) &&
                  <StyledLink active={location.pathname === '/'}>
                    <Link to="/"><ListAltSolid/>匯入管理</Link>
                  </StyledLink>
              }
              {
                checkAuthorityIsOpen('試題回報', authorityArray) &&
                  <StyledLink active={location.pathname === '/questionDisable'}>
                    <Link to="/questionDisable"><ReturnSolid/>停用試題</Link>
                  </StyledLink>
              }
              {
                checkAuthorityIsOpen('權限管理', authorityArray) &&
                  <StyledLinkGroup>
                    <div
                      className={authorityManagementIsOpenState ? 'active' : ''}
                      onClick={() => {authorityManagementIsOpenToggle();}}>
                      <div>
                        <CogSolid/>權限管理
                      </div>
                      <ChevronDownSolid/>
                    </div>
                    {
                      authorityManagementIsOpenState &&
                        <div>
                          <StyledLink active={location.pathname === '/authorityManagement/internalAccount'}>
                            <Link to="/authorityManagement/internalAccount"><UserSolid/>內部帳號管理</Link>
                          </StyledLink>
                          {/* <StyledLink>
                            <Link><KeySolid/>服務管理</Link>
                          </StyledLink>
                          <StyledLink>
                            <Link><ScrollSolid/>試卷標籤管理管理</Link>
                          </StyledLink>
                          <StyledLink>
                            <Link><TagsSolid/>標籤管理</Link>
                          </StyledLink> */}
                        </div>
                    }
                  </StyledLinkGroup>
              }
            </div>
          </div>
        }
        header={
          <StyledHeader>
            <div className="logo"><img src={'/assets/logo.svg'} alt='logo' /></div>
            <Dropdown className="userAvatar"
              list={[
                { name: '前台首頁', icon: <HomeSolid />, onClick: () => {window.location.href = `https://onepaper${env !== 'release' ? `-${env}` : ''}.oneclass.com.tw/`;} },
                { name: '個人設定', icon: <UserCogSolid />, onClick: () => {window.location.href = `https://onepaper${env !== 'release' ? `-${env}` : ''}.oneclass.com.tw/memberCenter`;} },
                { name: '登出', icon: <SignOutSolid />, onClick: () => {logout();} }
              ]}>
              <UiUserBox>
                <UserSolid />
                {authState.context.userDetails?.userProfile.name || ''}
              </UiUserBox>

            </Dropdown>
          </StyledHeader>
        }
        footer={<div>© NANI BOOK ENTERPRISE CO.,LTD. All Rights Reserved.</div>}
      >
        {
          authState.value === 'loggedIn' &&
          <div className='mainLayoutContent'>
            <RenderRoutes routes={routes} />
          </div>

        }
      </SidebarLayout>

    </StyledMainLayout>
  );
};

MainLayout.propTypes = {
  className: PropTypes.string,
  routes: PropTypes.array,
};
