import styled from 'styled-components';

export const StyledHeader = styled.div`

	display: flex;
	justify-content: space-between;
	align-items: center;
	
	.title{
		font-size: 24px;
		font-weight: 500;
	}
`;

export const StyledFilterBar = styled.div`
    position: relative;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	> *{
		margin-bottom: 8px;
		&:not(:last-child){
			margin-right: 8px;
		}
	}
`;